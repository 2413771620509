export const settings = {
    UISettings: {
        logo: '/images/logo-kaan-dark.png',
        logoLight: '/images/logo-kaan-light.png',
        marketplaceBrandName: '420.Work | First Cannabis NFT Marketplace',
        marketplaceBrandDescription: 'First Cannabis NFT Marketplace',
        marketplaceAuthor: 'Web3 Monkeys | Mohamed Hasan & Mohamed Saad',
        contactFormAddressId: 'mvoyrneb',
        nftReportFormId: 'mvoyrneb',
        newsletterAddressId: 'mwkzbjgr',
        usedNetworkName: 'Binance Smart Chain',
        videoBannerUrl: 'https://www.youtube.com/watch?v=S4PfVHrloZ8',
        heroImages: ['/cube-4.png', '/cube-3.png', '/cube-2.png'],
        partners: [
            { name: '', img: '/images/logo-kaan-dark.png' },
            { name: '', img: '/images/logo-kaan-dark.png' },
            { name: '', img: '/images/logo-kaan-dark.png' },
            { name: '', img: '/images/logo-kaan-dark.png' },
            { name: '', img: '/images/logo-kaan-dark.png' },
        ],
    },
    API_ENDPOINT: 'https://apix.420.work/api',
    currency: 'BNB',
    rpcUrl: 'https://binance.llamarpc.com',
    networkId: 56,
    blockExplorerUrls: 'https://bscscan.com/',
    NFTmaxSize: 10024,
    maxFeaturedItems: 10,
    IPFSProjectID: '2LKZlPp7ooFDBgcQKVE4DvV4C08',
    IPFSProjectSecret: '6f9c9cbdccd2df0a583c59896f0a86d9',
    IPFSGateway: '420work',
    mapCenter: [37.07934929117836, -8.122148561139827],
    userDefaultMintLimit: 2, // This is the user default limit, do you want to change it? yes please because its too high we set it 2 for example but later we can added more by user for admin panel
    royalties: [
        { label: 'None', value: 0 },
        { label: '5%', value: 5 },
        { label: '10%', value: 10 },
        { label: '15%', value: 15 },
        { label: '20%', value: 20 },
    ],
    saleCommission: 25, // 1000 represents 100%, e.g. 25 represents 2.5% etc...
};
