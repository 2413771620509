import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { categorySelectBox } from '../../helpers/constants';
import { settings } from '../../helpers/settings';
import Select from 'react-dropdown-select';
import { Tooltip } from 'react-tippy';
import { FaList, FaTable } from 'react-icons/fa';

// HOOKS
import useMarketplace from '../../hooks/useMarketplace';
import useAuctions from '../../hooks/useAuctions';
import useCollection from '../../hooks/useCollection';

// COMPONENTS
import PageBanner from '../../components/general/PageBanner';
import NftItem from '../../components/general/NftItem';
import MetaMaskLoader from '../../components/general/MetaMaskLoader';
import FullScreenLoader from '../../components/general/FullScreenLoader';
import ExploreListTable from './ExploreListTable';

// SELECT OPTIONS
const priceOptions = [
    { label: 'All', value: 'all' },
    { label: 'Only on Sale', value: 'saleOnly' },
    { label: 'Not for Sale', value: 'notForSale' },
];

const sorting = [
    { label: 'Newest First', value: 'newest' },
    { label: 'Oldest First', value: 'oldest' },
    { label: 'Highest Price', value: 'highPrice' },
    { label: 'Lowest Price', value: 'lowPrice' },
];

function ExplorePage() {
    const collectionCtx = useCollection();
    const marketplaceCtx = useMarketplace();
    const auctionCtx = useAuctions();

    const [baseFilter, setBaseFilter] = useState('all');
    const [listView, setListView] = useState('cube');
    const [sortFilter, setSortFilter] = useState('newest');
    const [priceFilter, setPriceFilter] = useState('all');
    const [withPriceCollection, setWithPriceCollection] = useState([]);
    const [renderedItems, setRenderedItems] = useState(15);

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `Explore NFTs | ${settings.UISettings.marketplaceBrandName}`;
    }, []);

    /*** =============================================== */
    //      MERGE NFT COLLECTION WITH NFT OFFERS
    /*** =============================================== */
    useEffect(() => {
        if (marketplaceCtx.contract && collectionCtx.contract && collectionCtx.collection.length > 0) {
            setWithPriceCollection(collectionCtx.collection);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        marketplaceCtx.offers,
        collectionCtx.contract,
        marketplaceCtx.contract,
        collectionCtx.collection,
        auctionCtx.auctions,
    ]);

    /*** =============================================== */
    //      GET BASE CATEGORIES
    /*** =============================================== */
    const baseExploreItems = useMemo(() => {
        if (baseFilter === 'all') {
            return collectionCtx.collection
                .filter((nft) => nft.isApproved === true)
                .filter(
                    (nft) =>
                        !auctionCtx.auctions
                            .filter((auc) => auc.isActive === true)
                            .some((auc) => nft.id === auc.tokenId)
                );
        } else {
            return collectionCtx.collection
                .filter((item) => item.category === baseFilter)
                .filter(
                    (nft) =>
                        !auctionCtx.auctions
                            .filter((auc) => auc.isActive === true)
                            .some((auc) => nft.id === auc.tokenId)
                )
                .filter((nft) => nft.isApproved === true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        priceFilter,
        sortFilter,
        baseFilter,
        collectionCtx.collection,
        withPriceCollection,
        auctionCtx.auctions,
        auctionCtx.auctionsData,
    ]);

    /*** =============================================== */
    //      FORMATE ACCORDING TO SALE STATUS
    /*** =============================================== */
    const priceFilteredItems = useMemo(() => {
        if (priceFilter === 'saleOnly') {
            return baseExploreItems.filter((item) => item.hasOffer);
        } else if (priceFilter === 'notForSale') {
            return baseExploreItems.filter((item) => !item.hasOffer);
        } else {
            return baseExploreItems;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseFilter, sortFilter, priceFilter, baseExploreItems, collectionCtx.collection]);

    /*** =============================================== */
    //      SORTING COLLECTION
    /*** =============================================== */
    const updatedExploreItems = useMemo(() => {
        if (sortFilter === 'newest') {
            return priceFilteredItems.sort((a, b) => {
                return new Date(b.dateCreated) - new Date(a.dateCreated);
            });
        } else if (sortFilter === 'oldest') {
            return priceFilteredItems.sort((a, b) => {
                return new Date(a.dateCreated) - new Date(b.dateCreated);
            });
        } else if (sortFilter === 'highPrice') {
            return withPriceCollection
                .filter((x) => priceFilteredItems.some((y) => x.id === y.id))
                .filter((el) => el.price > 0)
                .sort((a, b) => {
                    return b.price - a.price;
                });
        } else if (sortFilter === 'lowPrice') {
            return withPriceCollection
                .filter((x) => priceFilteredItems.some((y) => x.id === y.id))
                .filter((el) => el.price > 0)
                .sort((a, b) => {
                    return a.price - b.price;
                });
        } else {
            return priceFilteredItems;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortFilter, priceFilteredItems, priceFilter, baseExploreItems]);

    return (
        <>
            {auctionCtx.fetchingLoading ? <FullScreenLoader heading='Updating NFTs' /> : null}
            {marketplaceCtx.mktIsLoading ? <FullScreenLoader heading='Fetching NFTs' /> : null}
            {collectionCtx.nftTransactionLoading ? <MetaMaskLoader /> : null}
            {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}
            <PageBanner heading={'Explore All NFTs'} />
            <section className='py-5'>
                {/* FILTER CONTROLS */}
                <div className='container pt-5'>
                    {collectionCtx.collection
                        .filter(
                            (nft) =>
                                !auctionCtx.auctions
                                    .filter((auc) => auc.isActive === true)
                                    .some((auc) => nft.id === auc.tokenId)
                        )
                        .filter((nft) => nft.isPromoted).length > 0 && (
                        <>
                            <header className='mb-4'>
                                <div className='row'>
                                    <div className='col-lg-7 text-center mx-auto'>
                                        <h2 data-aos='fade-up' data-aos-delay='100'>
                                            Our Featured NFTs
                                        </h2>
                                        <p className='lead text-muted' data-aos='fade-up' data-aos-delay='200'>
                                            We're excited to share our favorite non-fungible tokens !
                                        </p>
                                    </div>
                                </div>
                            </header>

                            <div className='row justify-content-center row-cols-xxxl-5 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 gy-5 mb-5'>
                                {collectionCtx.collection
                                    .filter(
                                        (nft) =>
                                            !auctionCtx.auctions
                                                .filter((auc) => auc.isActive === true)
                                                .some((auc) => nft.id === auc.tokenId)
                                    )
                                    .filter((nft) => nft.isApproved)
                                    .filter((nft) => nft.isPromoted)
                                    .map((NFT, key) => {
                                        return (
                                            <div className={`col ${NFT.category}`} key={NFT.id}>
                                                <NftItem {...NFT} index={key} />
                                            </div>
                                        );
                                    })}
                            </div>
                        </>
                    )}

                    {collectionCtx.collection.length !== 0 && collectionCtx.totalSupply !== '0' && (
                        <header className='mb-2'>
                            <div className='row g-3 align-items-lg-end'>
                                <div className='col-lg-8'>
                                    <ul className='list-inline mb-0'>
                                        <li className='list-inline-item mb-3 me-3'>
                                            <p className='text-sm fw-bold pe-lg-4 mb-3'>Filter by Category</p>
                                            <div className='input-icon flex-nowrap category-select'>
                                                <div className='input-icon-text bg-none'>
                                                    <i className='las la-icons text-primary z-index-20'></i>
                                                </div>
                                                <Select
                                                    searchable={false}
                                                    options={categorySelectBox}
                                                    className='form-select rounded-xl border-gray-300 shadow-0 bg-white'
                                                    value={baseFilter}
                                                    onChange={(values) => {
                                                        setBaseFilter(values.map((el) => el.value).toString());
                                                    }}
                                                />
                                            </div>
                                        </li>
                                        <li className='list-inline-item mb-3 me-3'>
                                            <p className='text-sm fw-bold pe-lg-4 mb-3'>Filter by Price</p>
                                            <div className='input-icon flex-nowrap category-select'>
                                                <div className='input-icon-text bg-none'>
                                                    <i className='lab la-ethereum text-primary z-index-20'></i>
                                                </div>
                                                <Select
                                                    searchable={false}
                                                    options={priceOptions}
                                                    className='form-select rounded-xl border-gray-300 shadow-0 bg-white'
                                                    value={priceFilter}
                                                    onChange={(values) =>
                                                        setPriceFilter(values.map((el) => el.value).toString())
                                                    }
                                                />
                                            </div>
                                        </li>
                                        <li className='list-inline-item mb-3'>
                                            <p className='text-sm fw-bold pe-lg-4 mb-3'>Sort By</p>
                                            <div className='input-icon flex-nowrap category-select'>
                                                <div className='input-icon-text bg-none'>
                                                    <i className='lab la-ethereum text-primary z-index-20'></i>
                                                </div>
                                                <Select
                                                    searchable={false}
                                                    options={sorting}
                                                    className='form-select rounded-xl border-gray-300 shadow-0 bg-white'
                                                    value={sortFilter}
                                                    onChange={(values) =>
                                                        setSortFilter(values.map((el) => el.value).toString())
                                                    }
                                                />
                                            </div>
                                        </li>
                                        <li className='list-inline-item mb-3'>
                                            <Tooltip
                                                title='NFTs update in real time'
                                                position='top'
                                                trigger='mouseenter'
                                            >
                                                <div
                                                    className='form-control bg-white px-4'
                                                    data-bs-toggle='tooltip'
                                                    data-bs-placement='top'
                                                    title='hey'
                                                    style={{ transform: 'translateY(3px)' }}
                                                >
                                                    <div className='d-flex align-items-center'>
                                                        <div className='animated-bullet me-2'>
                                                            <div className='animated-bullet-inner'></div>
                                                        </div>
                                                        Live
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-lg-4 text-lg-end'>
                                    <ul className='list-inline p-0 mb-3'>
                                        <li className='list-inline-item'>
                                            <button
                                                className={`btn btn-${
                                                    listView === 'list' ? 'primary' : 'secondary'
                                                } btn-sm p-2`}
                                                onClick={() => setListView('list')}
                                            >
                                                <FaList size={18} />
                                            </button>
                                        </li>
                                        <li className='list-inline-item'>
                                            <button
                                                className={`btn btn-${
                                                    listView === 'cube' ? 'primary' : 'secondary'
                                                } btn-sm p-2`}
                                                onClick={() => setListView('cube')}
                                            >
                                                <FaTable size={18} />
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </header>
                    )}

                    {collectionCtx.collection.length !== 0 && collectionCtx.totalSupply !== '0' ? (
                        listView === 'cube' ? (
                            <>
                                <div className='row justify-content-center row-cols-xxxl-5 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 gy-5 mb-5'>
                                    {updatedExploreItems.slice(0, renderedItems).map((NFT, key) => {
                                        return (
                                            <div className={`col ${NFT.category}`} key={NFT.id}>
                                                <NftItem {...NFT} noAnimation={true} index={key} />
                                            </div>
                                        );
                                    })}
                                </div>
                                {updatedExploreItems.length > renderedItems && (
                                    <div className='text-center pt-4'>
                                        <button
                                            className='btn btn-dark'
                                            type='button'
                                            onClick={() => setRenderedItems(renderedItems + 5)}
                                        >
                                            Load More
                                        </button>
                                    </div>
                                )}
                            </>
                        ) : (
                            <ExploreListTable data={updatedExploreItems} />
                        )
                    ) : (
                        <>
                            <div className='text-center'>
                                <h4 className='text-center'>There're no NFTs at the moment</h4>
                                <p className='text-muted mb-3'>Once you've created an NFT we'll render it here</p>
                                <Link className='btn btn-gradient-primary mb-5' to='/mint'>
                                    Create NFT
                                </Link>
                            </div>
                        </>
                    )}

                    {collectionCtx.collection.length !== 0 &&
                        collectionCtx.totalSupply !== '0' &&
                        updatedExploreItems.length === 0 && (
                            <h4 className='text-center'>There're no NFTs match your filter</h4>
                        )}
                </div>
            </section>
        </>
    );
}

export default ExplorePage;
