import React, { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BsPatchCheckFill } from 'react-icons/bs';

// HOOKS
import useMarketplace from '../../hooks/useMarketplace';
import useUser from '../../hooks/useUser';
import useAuctions from '../../hooks/useAuctions';
import useCollection from '../../hooks/useCollection';

// COMPONENTS
import FullScreenLoader from '../../components/general/FullScreenLoader';
import NftItem from '../../components/general/NftItem';
import AuctionItem from '../../components/general/AuctionItem';

function NewSearchPage() {
    const collectionCtx = useCollection();
    const userCtx = useUser();
    const auctionCtx = useAuctions();
    const marketplaceCtx = useMarketplace();
    const { query } = useParams();

    const searchedUsers = useMemo(() => {
        return userCtx?.usersList?.filter((user) =>
            user?.fullName?.toLowerCase()?.includes(query?.toLowerCase()?.trim())
        );
    }, [query, userCtx.usersList]);

    const searchedNFTs = useMemo(() => {
        return collectionCtx?.collection
            ?.filter((nft) => nft.isApproved)
            .filter((nft) => nft?.title?.toLowerCase()?.includes(query?.toLowerCase()?.trim()));
    }, [query, collectionCtx.collection]);

    const searchedAuctions = useMemo(() => {
        return auctionCtx?.auctionsData
            ?.filter((auc) => auc.active === true)
            ?.title?.toLowerCase()
            ?.includes(query?.toLowerCase()?.trim());
    }, [query, auctionCtx.auctionsData]);

    console.log(searchedUsers);

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `Search Assets | ${query}`;
    }, [query]);

    return (
        <>
            {collectionCtx.collection.length === 0 ? <FullScreenLoader heading='Loading' /> : null}
            <section className='py-5 position-relative page-banner'>
                <div className='container py-5 mt-5 z-index-20'>
                    <div className='row mt-5'>
                        <div className='col-lg-7'>
                            <h1 className='h2 mb-0'>Search Results</h1>
                            <p className='lead mb-0'>
                                <span className='me-1 text-muted'>About</span> {query}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`pb-5 ${marketplaceCtx.themeMode === 'dark' && 'bg-light'}`}>
                <div className='container pb-5'>
                    <header>
                        <h2 className='text-center h3'>Artists</h2>
                    </header>
                    {searchedUsers && searchedUsers?.length > 0 ? (
                        <ul className='list-inline mb-0 d-flex flex-wrap justify-content-center mt-4'>
                            {searchedUsers?.map((user, index) => {
                                return (
                                    <li className='list-inline-item mx-3' key={index}>
                                        <Link className='text-reset' to={`/artists/${user?.account}`}>
                                            <div className='author-avatar author-avatar-lg mb-3'>
                                                <span
                                                    className='author-avatar-inner'
                                                    style={{
                                                        background: `url(${user?.avatar})`,
                                                    }}
                                                ></span>
                                            </div>
                                            <h5 className='fw-normal text-muted'>
                                                <div className='d-flex align-items-center'>
                                                    {user?.fullName}
                                                    {userCtx?.verifiedUsers?.includes(user?.account) && (
                                                        <span className='blue-tick static'>
                                                            <BsPatchCheckFill
                                                                size='1rem'
                                                                className='ms-1'
                                                                style={{ transform: 'translateY(-2px)' }}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            </h5>
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <p className='mb-0 mt-3 text-center'>
                            <span className='text-muted'>There're no NFTs matched the search of</span>{' '}
                            <span>{query}</span>
                        </p>
                    )}
                </div>
            </section>

            <section className='py-5'>
                <div className='container py-5'>
                    <header>
                        <h2 className='text-center h3'>NFTs</h2>
                    </header>

                    {searchedNFTs && searchedNFTs?.length > 0 ? (
                        <div className='row row-cols-xxxl-5 row-cols-xxl-4 justify-content-center row-cols-xl-3 row-cols-lg-3 gy-5 mb-5 mt-3'>
                            {searchedNFTs
                                ?.filter((nft) => nft?.isApproved)
                                ?.filter(
                                    (nft) =>
                                        !auctionCtx.auctions
                                            .filter((auc) => auc.isActive === true)
                                            .some((auc) => nft.id === auc.tokenId)
                                )
                                ?.map((nft, index) => {
                                    return (
                                        <div className='col' key={index}>
                                            <NftItem {...nft} index={index} />
                                        </div>
                                    );
                                })}
                        </div>
                    ) : (
                        <p className='mb-0 mt-3 text-center'>
                            <span className='text-muted'>There're no NFTs matched the search of</span>{' '}
                            <span>{query}</span>
                        </p>
                    )}
                </div>
            </section>

            <section className={`py-5 ${marketplaceCtx.themeMode === 'dark' && 'bg-light'}`}>
                <div className='container py-5'>
                    <header>
                        <h2 className='text-center h3'>Auctions</h2>
                    </header>

                    {searchedAuctions ? (
                        searchedAuctions?.length >
                        0(
                            <div className='row row-cols-xxxl-5 row-cols-xxl-4 justify-content-center row-cols-xl-3 row-cols-lg-3 gy-5 mb-5 mt-4'>
                                {searchedAuctions?.map((auc, index) => {
                                    return (
                                        <div className='col' key={index}>
                                            <AuctionItem {...auc} index={index} />
                                        </div>
                                    );
                                })}
                            </div>
                        )
                    ) : (
                        <p className='mb-0 mt-3 text-center'>
                            <span className='text-muted'>There're no Auctions matched the search of</span>{' '}
                            <span>{query}</span>
                        </p>
                    )}
                </div>
            </section>
        </>
    );
}

export default NewSearchPage;
