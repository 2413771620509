import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { settings } from '../../helpers/settings';
import DataTable from 'react-data-table-component';
import { BsPatchCheckFill } from 'react-icons/bs';

// HOOKS
import useMarketplace from '../../hooks/useMarketplace';
import useCollection from '../../hooks/useCollection';
import useUser from '../../hooks/useUser';
import Axios from 'axios';

function UsersTable({ increaseLimit }) {
    const userCtx = useUser();
    const marketplaceCtx = useMarketplace();
    const collectionCtx = useCollection();

    const [usersList, setUsersList] = useState(null);

    function checkVerifiedUser(address) {
        if (userCtx?.verifiedUsers.includes(address)) {
            return true;
        } else {
            return false;
        }
    }

    /*** -------------------------------------------- */
    //      GET USERS LIST
    /*** -------------------------------------------- */
    useEffect(() => {
        if (userCtx.usersList && userCtx.contract && marketplaceCtx.sellers) {
            setUsersList(
                userCtx.usersList.map((user) => {
                    const isSeller = marketplaceCtx.sellers.filter((seller) => seller.address === user.account)[0];
                    return {
                        account: user.account,
                        name: user.fullName,
                        email: user.email,
                        avatar: user.avatar,
                        mintLimit: user.mintLimit,
                        selling: isSeller ? parseInt(isSeller.value) : 0,
                    };
                })
            );
        }
    }, [userCtx.usersList, marketplaceCtx.contract, marketplaceCtx.sellers, userCtx.contract]);

    /* --------------------------------------------- 
          VERIFY USER
    --------------------------------------------- */
    async function verifyUser(address) {
        try {
            const res = await Axios.post(`${settings.API_ENDPOINT}/verifications`, { address });
            const resCode = await res?.data?.code;

            if (resCode === 200) {
                userCtx.getVerifiedUsers();
            }
        } catch (err) {
            console.log(err);
        }
    }

    /* --------------------------------------------- 
          UnVERIFY USER
    --------------------------------------------- */
    async function unverifyUser(address) {
        try {
            const res = await Axios.delete(`${settings.API_ENDPOINT}/verifications?address=${address}`);
            const resCode = await res?.data?.code;

            if (resCode === 200) {
                userCtx.getVerifiedUsers();
            }
        } catch (err) {
            console.log(err);
        }
    }

    /*** -------------------------------------------- */
    //      TABLE COLUMNS
    /*** -------------------------------------------- */
    const columns = [
        {
            name: 'User Info',
            selector: (row) => row?.fullName,
            cell: (row) => (
                <div row={row}>
                    <Link className='text-reset' to={`/artists/${row?.account}`}>
                        <div className='d-flex align-items-center py-3'>
                            <div className='author-avatar'>
                                <span
                                    className='author-avatar-inner'
                                    style={{ background: `url(${row?.avatar})` }}
                                ></span>
                            </div>
                            <div className='ms-3'>
                                <p className='fw-bold text-base mb-0'>
                                    <div className='d-flex align-items-center'>
                                        {row?.name}{' '}
                                        {userCtx?.verifiedUsers?.includes(row?.account) && (
                                            <span className='blue-tick static'>
                                                <BsPatchCheckFill
                                                    size='0.8rem'
                                                    className='ms-1'
                                                    style={{ transform: 'translateY(-2px)' }}
                                                />
                                            </span>
                                        )}
                                    </div>
                                </p>
                                <p className='text-muted text-xxs mb-0'>{row?.email}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: 'Profits',
            selector: (row) => row?.selling,
            cell: (row) => (
                <div row={row}>
                    <div className='d-flex align-items-center'>
                        <p className='mb-0 fw-bold'>
                            {row?.selling > 0 ? (row?.selling / 10 ** 18).toFixed(2) : 0} {settings.currency}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            name: 'Total Minted',
            selector: (row) => row?.account,
            cell: (row) => (
                <div row={row}>
                    <div className='d-flex align-items-center'>
                        <p className='mb-0 fw-bold'>
                            {collectionCtx.collection.filter((nft) => nft.creator === row?.account).length}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            name: 'NFTs Limit',
            selector: (row) => row?.mintLimit,
            cell: (row) => (
                <div row={row}>
                    <div className='d-flex align-items-center'>
                        <p className='mb-0 fw-bold'>{row?.mintLimit}</p>
                    </div>
                </div>
            ),
        },
        {
            name: 'Add Additional Limit',
            selector: (row) => row?.mintLimit,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <form
                        className='d-flex align-items-center align-items-stretch'
                        onSubmit={(e) => increaseLimit(e, row?.account, Number(e.target.user_limit.value))}
                    >
                        <input
                            type='number'
                            className='form-control bg-white me-2 py-1'
                            style={{ minWidth: '100px' }}
                            min='1'
                            step='1'
                            placeholder='1 or more'
                            required={true}
                            name='user_limit'
                        />
                        <button className='btn btn-primary' type='submit'>
                            Upgrade
                        </button>
                    </form>
                </div>
            ),
        },
        {
            name: 'Verify',
            selector: (row) => row?.mintLimit,
            cell: (row) => (
                <div row={row}>
                    {!checkVerifiedUser(row?.account) ? (
                        <button className='btn btn-sm btn-primary' onClick={() => verifyUser(row?.account)}>
                            Verify
                        </button>
                    ) : (
                        <button className='btn btn-sm btn-danger' onClick={() => unverifyUser(row?.account)}>
                            Unverify
                        </button>
                    )}
                </div>
            ),
        },
    ];

    return (
        usersList && (
            <DataTable
                columns={columns}
                data={usersList}
                pagination={usersList.length >= 10 && true}
                responsive
                theme={marketplaceCtx.themeMode === 'dark' && 'solarized'}
            />
        )
    );
}

export default UsersTable;
