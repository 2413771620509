import React, { useEffect, useState } from 'react';
import NftItem from '../../components/general/NftItem';
import AuctionItem from '../../components/general/AuctionItem';

import useCollection from '../../hooks/useCollection';
import useAuctions from '../../hooks/useAuctions';

function HeroImages() {
    const collectionCtx = useCollection();
    const auctionCtx = useAuctions();
    const [featuredNft, setFeaturedNft] = useState(null);

    useEffect(() => {
        if (collectionCtx.contract && collectionCtx.collection.length > 0) {
            const promotedNfts = collectionCtx.collection
                .filter((nft) => nft.isApproved === true)
                .filter((nft) => nft.isPromoted === true);
            setFeaturedNft(promotedNfts.length > 0 && [promotedNfts[Math.floor(Math.random() * promotedNfts.length)]]);
        }
    }, [collectionCtx.contract, collectionCtx.collection]);

    return (
        <>
            {featuredNft && featuredNft.length > 0 ? (
                featuredNft[0].inAuction === true ? (
                    auctionCtx.auctionsData
                        .filter((auc) => auc.tokenId === featuredNft[0].id)
                        .map((AUC, key) => {
                            return (
                                <div className='row' key={key}>
                                    <div className='col-xl-9 mx-auto'>
                                        <AuctionItem {...AUC} nftKey={key} />
                                    </div>
                                </div>
                            );
                        })
                ) : (
                    featuredNft[0].inAuction === false &&
                    collectionCtx.collection
                        .filter((nft) => nft.id === featuredNft[0].id)
                        .map((NFT, key) => {
                            return (
                                <div className='row' key={key}>
                                    <div className='col-lg-9 mx-auto'>
                                        <NftItem {...NFT} />
                                    </div>
                                </div>
                            );
                        })
                )
            ) : (
                <img
                    src='/ils-2.png'
                    alt='Globe'
                    className='img-fluid d-block mx-auto'
                    data-aos='fade-left'
                    data-aos-delay='200'
                />
            )}
        </>
    );
}

export default HeroImages;
