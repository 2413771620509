import React, { useEffect } from 'react';
import AOS from 'aos';

function ContactInfo(props) {
    /*** =============================================== */
    //      INITIATE AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <div className={props.gridWidth}>
            <div className='row gy-3'>
                <div className='col-lg-4 d-flex' data-aos='fade-up' data-aos-delay='200'>
                    <div className='contact-icon bd-3 border-primary text-primary flex-shrink-0'>
                        <i className='las la-globe'></i>
                    </div>
                    <div className='ms-3'>
                        <h6>Company Address</h6>
                        <p className='text-sm text-muted mb-0'>
                            Avenida Cerro da Vila, Edifício Vilamarina Levante 8, Vilamoura 8125-403-QUARTEIRA- Portugal
                        </p>
                    </div>
                </div>
                <div className='col-lg-4 d-flex' data-aos='fade-up' data-aos-delay='300'>
                    <div className='contact-icon bd-3 border-primary text-primary flex-shrink-0'>
                        <i className='las la-phone'></i>
                    </div>
                    <div className='ms-3'>
                        <h6>Hot lines</h6>
                        <ul className='list-unstyled'>
                            <li>
                                <a
                                    className='text-decoration-none text-sm text-muted mb-1'
                                    rel='noreferrer'
                                    href='tel:#'
                                >
                                    -
                                </a>
                            </li>
                            <li>
                                <a
                                    className='text-decoration-none text-sm text-muted mb-0'
                                    rel='noreferrer'
                                    href='tel:#'
                                >
                                    -
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-lg-4 d-flex' data-aos='fade-up' data-aos-delay='400'>
                    <div className='contact-icon bd-3 border-primary text-primary flex-shrink-0'>
                        <i className='las la-envelope'></i>
                    </div>
                    <div className='ms-3'>
                        <h6>Email address</h6>
                        <ul className='list-unstyled mb-0'>
                            <li>
                                <a
                                    className='text-decoration-none text-sm text-muted mb-1'
                                    rel='noreferrer'
                                    href='mailto:info@420.work'
                                >
                                    info@420.work
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactInfo;
