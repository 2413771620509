import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from '@formspree/react';
import { settings } from '../../helpers/settings';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation } from 'swiper';

SwiperCore.use([Navigation]);

function Footer() {
    const [state, handleSubmit] = useForm('xlezgplp');

    return (
        <>
            <div className='bg-primary py-5'>
                <div className='container slider-no-padding text-center'>
                    <Swiper
                        spaceBetween={0}
                        center='true'
                        loop={true}
                        breakpoints={{
                            768: { slidesPerView: 2 },
                            900: { slidesPerView: 4 },
                            1200: { slidesPerView: 5 },
                            1400: { slidesPerView: 6 },
                        }}
                        navigation={false}
                    >
                        {settings.UISettings.partners.map((partner, key) => {
                            return (
                                <SwiperSlide key={key} className='p-0'>
                                    <img
                                        src={partner.img}
                                        alt={partner.name}
                                        className='img-fluid px-3'
                                        style={{ maxWidth: '140px' }}
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
            <footer className='footer bg-primary-darker text-white mt-auto pt-5'>
                <div className='container py-5 z-index-20'>
                    <div className='row gy-4'>
                        <div className='col-lg-6'>
                            <h4>Stay in the top</h4>
                            <p>
                                Join our mailing list to stay in the loop with our newest feature releases, NFT drops,
                                and tips and tricks for navigating 420.Work.
                            </p>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit(e);
                                    e.target.email.value = '';
                                }}
                            >
                                <div className='input-group'>
                                    <input
                                        className='form-control me-2 bg-white-stable border-white-stable shadow-0 ps-4 rounded'
                                        style={{ color: '#333' }}
                                        type='email'
                                        name='email'
                                        autoComplete='off'
                                        placeholder='Enter your email address...'
                                    />
                                    <button className='btn btn-primary px-4 rounded shadow-0' type='submit'>
                                        Subscribe
                                    </button>
                                </div>
                                {state.succeeded ? (
                                    <p className='bg-primary text-white mt-1 px-3 py-1 rounded-sm'>
                                        Thanks for subscribing!
                                    </p>
                                ) : null}
                            </form>
                        </div>

                        <div className='col-lg-6'>
                            <h4>Join the community</h4>
                            <ul className='list-inline mb-0'>
                                <li className='list-inline-item'>
                                    <a
                                        className='social-link bg-primary text-white rounded-xl bg-hover-primary'
                                        rel='noreferrer'
                                        href='/'
                                    >
                                        <i className='lab la-facebook-f'></i>
                                    </a>
                                </li>
                                <li className='list-inline-item'>
                                    <a
                                        className='social-link bg-primary text-white rounded-xl bg-hover-primary'
                                        rel='noreferrer'
                                        href='/'
                                    >
                                        <i className='lab la-instagram'></i>
                                    </a>
                                </li>
                                <li className='list-inline-item'>
                                    <a
                                        className='social-link bg-primary text-white rounded-xl bg-hover-primary'
                                        rel='noreferrer'
                                        href='/'
                                    >
                                        <i className='lab la-pinterest'></i>
                                    </a>
                                </li>
                                <li className='list-inline-item'>
                                    <a
                                        className='social-link bg-primary text-white rounded-xl bg-hover-primary'
                                        rel='noreferrer'
                                        href='/'
                                    >
                                        <i className='lab la-twitter'></i>
                                    </a>
                                </li>
                                <li className='list-inline-item'>
                                    <a
                                        className='social-link bg-primary text-white rounded-xl bg-hover-primary'
                                        rel='noreferrer'
                                        href='/'
                                    >
                                        <i className='las la-link'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='my-5 py-3'>
                        <div className='border-top opacity-4'></div>
                    </div>

                    <div className='row gy-4'>
                        <div className='col-lg-4 col-md-12'>
                            <img
                                src={settings.UISettings.logo}
                                alt={settings.UISettings.marketplaceBrandName}
                                className='mb-3'
                                width='140'
                            />
                            <p>
                                The world's first cannabis based digital marketplace for crypto collectibles and
                                non-fungible tokens (NFTS).
                                <br />
                                Buy, sell and discver exclusive digital items.
                            </p>
                        </div>
                        <div className='col-lg-2 col-md-6'>
                            <h6 className='mb-4'>Marketplace</h6>
                            <ul className='list-unstyled mb-0'>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/explore'>
                                        All NFTs
                                    </Link>
                                </li>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/auctions'>
                                        Auctions
                                    </Link>
                                </li>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/categories/art'>
                                        Art
                                    </Link>
                                </li>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/categories/music'>
                                        Music
                                    </Link>
                                </li>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/categories/game'>
                                        Game
                                    </Link>
                                </li>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/categories/memes'>
                                        Memes
                                    </Link>
                                </li>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/categories/trendingcards'>
                                        Trending Cards
                                    </Link>
                                </li>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/categories/collectibles'>
                                        Collectibles
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className='col-lg-2 col-md-6'>
                            <h6 className='mb-4'>My Account</h6>
                            <ul className='list-unstyled mb-4'>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/my-account'>
                                        My Profile
                                    </Link>
                                </li>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/my-account'>
                                        My Collections
                                    </Link>
                                </li>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/my-account'>
                                        Settings
                                    </Link>
                                </li>
                            </ul>

                            <h6 className='mb-4'>Stats</h6>
                            <ul className='list-unstyled mb-4'>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/activity'>
                                        Activity
                                    </Link>
                                </li>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/sellers'>
                                        Sellers
                                    </Link>
                                </li>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/artists'>
                                        Artists
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className='col-lg-2 col-md-6'>
                            <h6 className='mb-4'>Resources</h6>
                            <ul className='list-unstyled mb-4'>
                                <li className='mb-2'>
                                    <a
                                        className='text-white-stable'
                                        href='https://www.qoomed.com/content-library'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Help Center
                                    </a>
                                </li>
                                <li className='mb-2'>
                                    <a
                                        className='text-white-stable'
                                        href='https://www.qoomed.com/qoomed-status'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Platform Status
                                    </a>
                                </li>
                                <li className='mb-2'>
                                    <a
                                        className='text-white-stable'
                                        href='https://dashboard.qoomed.com/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Buy Qoomed
                                    </a>
                                </li>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/faqs'>
                                        FAQs
                                    </Link>
                                </li>
                                <li className='mb-2'>
                                    <a
                                        className='text-white-stable'
                                        href='https://www.qoomed.com/blog'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Blog
                                    </a>
                                </li>
                                <li className='mb-2'>
                                    <a
                                        className='text-white-stable'
                                        href='https://www.qoomed.com/content-library'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Docs
                                    </a>
                                </li>
                                <li className='mb-2'>
                                    <a
                                        className='text-white-stable'
                                        href='https://www.qoomed.com/blog'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Newsletter
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='col-lg-2'>
                            <h6 className='mb-4'>Company</h6>
                            <ul className='list-unstyled mb-4'>
                                <li className='mb-2'>
                                    <a
                                        className='text-white-stable'
                                        href='https://www.qoomed.com/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        About
                                    </a>
                                </li>
                                <li className='mb-2'>
                                    <a
                                        className='text-white-stable'
                                        href='https://www.qoomed.com/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Careers
                                    </a>
                                </li>
                                <li className='mb-2'>
                                    <a
                                        className='text-white-stable'
                                        href='https://www.qoomed.com/cannabis-industry'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Solutions
                                    </a>
                                </li>
                                <li className='mb-2'>
                                    <a
                                        className='text-white-stable'
                                        href='https://www.qoomed.com/a-platform-for-the-next-generation'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Projects
                                    </a>
                                </li>
                                <li className='mb-2'>
                                    <Link className='text-white-stable' to='/contact'>
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='py-3'>
                    <div className='container'>
                        <div className='border-top opacity-4'></div>
                    </div>
                </div>

                <div className='container py-4 z-index-20'>
                    <div className='row gy-3'>
                        <div className='col-lg-6 text-center text-lg-start'>
                            <p className='text-sm mb-0'>
                                © {new Date().getFullYear()} All rights reserved. 420.Work by{' '}
                                <a
                                    className='text-white-stable'
                                    href='https://new.qoomed.com/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    QOOMED UNIPESSOAL LDA.
                                </a>
                            </p>
                        </div>
                        <div className='col-lg-6 text-center text-lg-end'>
                            <ul className='list-inline mb-0'>
                                <li className='list-inline-item'>
                                    <Link className='text-white-stable text-sm' to='/privacy-policy'>
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li className='list-inline-item'>
                                    <Link className='text-white-stable text-sm' to='/terms'>
                                        Terms of Services
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
