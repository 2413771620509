import React from 'react';
import { Link } from 'react-router-dom';

export const techQuestions = [
    {
        question: 'What is 420Work',
        answer: '420Work is the NFT marketplace project owned by Qoomed.',
    },
    {
        question: 'What is NFT?',
        answer: 'It is a unit of data stored in a digital ledger, called a blockchain, that confirms that a digital asset is unique and therefore not interchangeable.',
    },
    {
        question: 'What is NFT Marketplace?',
        answer: 'It is the platform where NFT assets are bought, sold and exhibited.',
    },
    {
        question: 'How can I become a member of 420Work?',
        answer: 'You can become a member by filling out the member form.',
    },
    {
        question: 'Why do I pay BNB gas fee for the transactions I make on 420Work?',
        answer: (
            <>
                <p>
                    420Work is designed with pure Blockchain technology. All transactions made on 420Work are recorded
                    on the blockchain.
                </p>
                <p>
                    Since 420Work is running on Binance Smart Chain, the network and communication costs determined by
                    Binance are requested in BNB under the name Gas Fee for every transaction.
                </p>
                <p className='mb-0'>
                    Gas Fee is fully paid into the Binance Smart Chain infrastructure. It has nothing to do with us.
                </p>
            </>
        ),
    },
    {
        question: 'What types of NFT can I do on 420Work?',
        answer: 'You can NFT all of the Image, Audio and Video files via QooART. You can first convert your 3D animations to video files and then upload them.',
    },
    {
        question: 'Why is all NFT generation supervised on 420Work?',
        answer: "This NFT marketplace, which we are the creator of, only allows the trading of cannabis-themed content. That's why the contents are controlled by our team.",
    },
    {
        question: 'Which Crypto Currencies can I pay with?',
        answer: '420Work only receives payment with Qoomed Token. You can visit qoomed.com to get Qoomed Tokens.',
    },
    {
        question: 'Which Crypto Wallets do you support?',
        answer: 'We work with Metamask, which is the most preferred in the world. To be able to trade on 420Work, you must be connected to the Binance Smart Chain Network and a Qoomed Token Owner.',
    },
    {
        question: 'Can I transfer the NFT I created in another marketplace or in another environment to 420Work?',
        answer: 'Hypothetically No, each marketplace has its own standard and might can not read from our contracts, however, the NFT created is still there on the Blockchain, even the marketplace itself is no longer exist, you can add it to your wallet and transfer it to another wallet.',
    },
    {
        question: 'What is Unlockable Content?',
        answer: 'Optionally, for each nft you create, you can share content that will only be seen by the purchaser. This content can be anything. However, it should be specific and specific to the purchaser.',
    },
    {
        question: 'More questions?',
        answer: (
            <p>
                If you con't find the answers here,{' '}
                <Link className='text-primary' to='/contact'>
                    get in touch
                </Link>
                . We will be happy to help
            </p>
        ),
    },
];
