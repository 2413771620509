import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { settings } from '../../helpers/settings';
import { formatPrice } from '../../helpers/utils';
import { Tooltip } from 'react-tippy';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import Select from 'react-dropdown-select';
import { categorySelectBox } from '../../helpers/constants';
import { FaList, FaTable } from 'react-icons/fa';
import Axios from 'axios';
import { BsPatchCheckFill } from 'react-icons/bs';

// HOOKS
import useMarketplace from '../../hooks/useMarketplace';
import useUser from '../../hooks/useUser';
import useAuctions from '../../hooks/useAuctions';
import useCollection from '../../hooks/useCollection';

// COMPONENTS
import NftItem from '../../components/general/NftItem';
// import AuctionItem from '../../components/general/AuctionItem';
import MetaMaskLoader from '../../components/general/MetaMaskLoader';
import FullScreenLoader from '../../components/general/FullScreenLoader';
import UserGalleryTable from './UserGalleryTable';
import useWeb3 from '../../hooks/useWeb3';

// SELECT OPTIONS
const priceOptions = [
    { label: 'All', value: 'all' },
    { label: 'Only on Sale', value: 'saleOnly' },
    { label: 'Not for Sale', value: 'notForSale' },
];

const sorting = [
    { label: 'Newest First', value: 'newest' },
    { label: 'Oldest First', value: 'oldest' },
    { label: 'Highest Price', value: 'highPrice' },
    { label: 'Lowest Price', value: 'lowPrice' },
];

function UserGalleryPage({ topSellers }) {
    const marketplaceCtx = useMarketplace();
    const collectionCtx = useCollection();
    const userCtx = useUser();
    const auctionCtx = useAuctions();
    const web3Ctx = useWeb3();

    const [isTopSeller, setIsTopSeller] = useState(null);
    const [isNavSelected, setIsNavSelected] = useState('created');
    const [listView, setListView] = useState('cube');
    const [isCurrentUser, setIsCurrentUser] = useState(null);
    const { address } = useParams();
    const [userDetails, setUserDetails] = useState({
        avatar: '',
        header: '',
        fullName: '',
        role: '',
        about: '',
        facebook: '',
        dribbble: '',
        twitter: '',
        instagram: '',
    });

    const currentAuthors = useMemo(() => {
        return userCtx?.usersList
            ?.filter((user) => user?.account === address)
            ?.map((user) => {
                const isSeller = marketplaceCtx.sellers.filter((seller) => seller.address === user.account)[0];
                return {
                    ...user,
                    selling: isSeller ? parseInt(isSeller.value) : 0,
                };
            })[0];
    }, [userCtx.usersList, marketplaceCtx.sellers, address]);

    const [baseFilter, setBaseFilter] = useState('all');
    const [sortFilter, setSortFilter] = useState('newest');
    const [priceFilter, setPriceFilter] = useState('all');
    const [withPriceCollection, setWithPriceCollection] = useState([]);
    const [renderedItems, setRenderedItems] = useState(15);
    const [favList, setFavList] = useState([]);
    const [hasFav, setHasFav] = useState(false);
    const [pageViews, setPageViews] = useState(0);

    /*** =============================================== */
    //      MERGE NFT COLLECTION WITH NFT OFFERS
    /*** =============================================== */
    useEffect(() => {
        if (marketplaceCtx.contract && collectionCtx.contract && collectionCtx.collection.length > 0) {
            setWithPriceCollection(collectionCtx.collection);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        marketplaceCtx.offers,
        collectionCtx.contract,
        marketplaceCtx.contract,
        collectionCtx.collection,
        auctionCtx.auctions,
    ]);

    /*** =============================================== */
    //      GET BASE CATEGORIES
    /*** =============================================== */
    const baseExploreItems = useMemo(() => {
        if (baseFilter === 'all') {
            return collectionCtx.collection
                .filter((nft) => nft.isApproved === true)
                .filter(
                    (nft) =>
                        !auctionCtx.auctions
                            .filter((auc) => auc.isActive === true)
                            .some((auc) => nft.id === auc.tokenId)
                );
        } else {
            return collectionCtx.collection
                .filter((item) => item.category === baseFilter)
                .filter(
                    (nft) =>
                        !auctionCtx.auctions
                            .filter((auc) => auc.isActive === true)
                            .some((auc) => nft.id === auc.tokenId)
                )
                .filter((nft) => nft.isApproved === true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        priceFilter,
        sortFilter,
        baseFilter,
        collectionCtx.collection,
        withPriceCollection,
        auctionCtx.auctions,
        auctionCtx.auctionsData,
    ]);

    /*** =============================================== */
    //      FORMATE ACCORDING TO SALE STATUS
    /*** =============================================== */
    const priceFilteredItems = useMemo(() => {
        if (priceFilter === 'saleOnly') {
            return baseExploreItems.filter((item) => item.hasOffer);
        } else if (priceFilter === 'notForSale') {
            return baseExploreItems.filter((item) => !item.hasOffer);
        } else {
            return baseExploreItems;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseFilter, sortFilter, priceFilter, baseExploreItems, collectionCtx.collection]);

    /*** =============================================== */
    //      SORTING COLLECTION
    /*** =============================================== */
    const updatedExploreItems = useMemo(() => {
        if (sortFilter === 'newest') {
            return priceFilteredItems.sort((a, b) => {
                return new Date(b.dateCreated) - new Date(a.dateCreated);
            });
        } else if (sortFilter === 'oldest') {
            return priceFilteredItems.sort((a, b) => {
                return new Date(a.dateCreated) - new Date(b.dateCreated);
            });
        } else if (sortFilter === 'highPrice') {
            return withPriceCollection
                .filter((x) => priceFilteredItems.some((y) => x.id === y.id))
                .filter((el) => el.price > 0)
                .sort((a, b) => {
                    return b.price - a.price;
                });
        } else if (sortFilter === 'lowPrice') {
            return withPriceCollection
                .filter((x) => priceFilteredItems.some((y) => x.id === y.id))
                .filter((el) => el.price > 0)
                .sort((a, b) => {
                    return a.price - b.price;
                });
        } else {
            return priceFilteredItems;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortFilter, priceFilteredItems, priceFilter, baseExploreItems]);

    /*** ------------------------------------------------- */
    //      CHANGE PAGE TITLE
    /*** ------------------------------------------------- */
    useEffect(() => {
        document.title = `${userDetails.fullName} | ${settings.UISettings.marketplaceBrandName}`;
    }, [userDetails]);

    /*** ------------------------------------------------- */
    //      CHECK IF USER EXISTS
    /*** ------------------------------------------------- */
    useEffect(() => {
        if (userCtx.contract && userCtx.usersList.map((user) => user.account).includes(address)) {
            setIsCurrentUser(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, userCtx.usersList]);

    // /*** ------------------------------------------------- */
    // //      MERGE NFT COLLECTION WITH NFT OFFERS
    // /*** ------------------------------------------------- */
    // useEffect(() => {
    //     if (marketplaceCtx.contract && collectionCtx.contract && collectionCtx.collection.length > 0) {
    //         setAllCollection(
    //             collectionCtx.collection
    //                 .filter((nft) => nft.isApproved === true)
    //                 .filter((nft) => nft.inAuction === false)
    //         );
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [
    //     marketplaceCtx.offers,
    //     collectionCtx.collection,
    //     collectionCtx.contract,
    //     marketplaceCtx.contract,
    //     collectionCtx.collection,
    // ]);

    /*** ------------------------------------------------- */
    //      GET USER DETAILS
    /*** ------------------------------------------------- */
    useEffect(() => {
        if (userCtx.contract && userCtx.usersList && userCtx.usersList.length > 0 && isCurrentUser === true) {
            setUserDetails(userCtx.usersList.filter((user) => user.account === address)[0]);
        }
    }, [userCtx.contract, userCtx.usersList, address, isCurrentUser]);

    /*** ------------------------------------------------- */
    //      GET TOP SELLERS
    /*** ------------------------------------------------- */
    useEffect(() => {
        const topSellersList = topSellers.map((seller) => seller.address);
        setIsTopSeller(topSellersList.includes(address));
    }, [topSellers, address]);

    /*** ------------------------------------------------- */
    //      GET OWNED NFTS
    /*** ------------------------------------------------- */
    useEffect(() => {
        if (collectionCtx.contract && collectionCtx.collection && collectionCtx.collection.length > 0) {
            userCtx.loadUserAssets(collectionCtx.contract, address);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionCtx.contract, collectionCtx.collection, address]);

    /*** ------------------------------------------------- */
    //      GET CREATED NFTs
    /*** ------------------------------------------------- */
    const createdAssets = useMemo(() => {
        if (userCtx.userAssets) {
            return userCtx.userAssets.created;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCtx.userAssets, userCtx.contract]);

    /* --------------------------------------------- 
          FAVOURITE USER
    --------------------------------------------- */
    async function favUser() {
        try {
            const res = await Axios.post(`${settings.API_ENDPOINT}/favorites`, {
                address: userDetails?.account,
                sender: web3Ctx?.account,
            });
            const resCode = await res?.data?.code;

            if (resCode === 200) {
                getFavs();
            }
        } catch (err) {
            console.log(err);
        }
    }

    /* --------------------------------------------- 
          UNFAVOURITE USER
    --------------------------------------------- */
    async function unfavUser() {
        try {
            const res = await Axios.delete(
                `${settings.API_ENDPOINT}/favorites?address=${userDetails?.account}&sender=${web3Ctx?.account}`
            );
            const resCode = await res?.data?.code;

            if (resCode === 200) {
                getFavs();
            }
        } catch (err) {
            console.log(err);
        }
    }

    /* --------------------------------------------- 
          GET ALL USER FAVOURITES
    --------------------------------------------- */
    async function getFavs() {
        const res = await Axios(`${settings.API_ENDPOINT}/favorites`);
        const data = await res?.data?.response[address];
        const data2 = await res?.data?.response;

        setHasFav(
            Object?.keys(data2)
                ?.map((key) => key)
                ?.includes(address)
        );
        setFavList(data?.map((sender) => sender?.sender));
    }

    useEffect(() => {
        getFavs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    /* --------------------------------------------- 
          USER PAGE VIEWS
    --------------------------------------------- */
    async function sendPageViews() {
        try {
            Axios.post(`${settings?.API_ENDPOINT}/views`, {
                title: address,
            });
        } catch (err) {
            console.log(err);
        }
    }
    async function getPageViews() {
        try {
            const res = await Axios(`${settings?.API_ENDPOINT}/views`);
            const resCode = await res?.data?.code;
            const data = await res?.data?.response?.filter((rec) => rec?.title === address);

            if (resCode === 200) {
                setPageViews(data[0]?.count);
            } else {
                setPageViews(0);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        sendPageViews();
        getPageViews();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    const isVerified = useMemo(() => {
        if (userCtx.verifiedUsers.includes(userDetails?.account)) {
            return true;
        } else {
            return false;
        }
    }, [userCtx.verifiedUsers, userDetails.account]);

    if (!isCurrentUser) {
        return (
            <div className='container py-5'>
                <div className='row py-5 text-center'>
                    <div className='col-lg-6 mx-auto'>
                        <p className='mb-0 fw-bold' style={{ fontSize: '10rem' }}>
                            404
                        </p>
                        <h1 className='h2 text-uppercase'>Not Found</h1>
                        <p className='text-muted'>This page is not found, return to Home page</p>
                        <Link to='/' className='btn btn-gradient-primary'>
                            Homepage
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            {auctionCtx.fetchingLoading ? <FullScreenLoader heading='Updating Auctions' /> : null}
            {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}
            {collectionCtx.nftIsLoading ? <FullScreenLoader heading='loading' /> : null}
            {collectionCtx.nftTransactionLoading ? <MetaMaskLoader /> : null}
            <section className='py-5'>
                <div className='container py-5 mt-5'>
                    <div className='user-gallery-header'>
                        <div
                            className='user-gallery-header-inner'
                            style={{ background: `url(${userDetails.header})` }}
                        ></div>
                        {userDetails.avatar === '' ? (
                            <div className='user-gallery-avatar user-single-profile'>
                                <span
                                    className='user-gallery-avatar-inner'
                                    style={{ background: `url(/images/astronaut.png)` }}
                                ></span>
                            </div>
                        ) : (
                            <div className='user-gallery-avatar user-single-profile'>
                                <span
                                    className='user-gallery-avatar-inner'
                                    style={{ background: `url(${userDetails.avatar})` }}
                                ></span>
                            </div>
                        )}
                    </div>
                    <div className='pt-5 text-center mt-5'>
                        <h1 className='h4 mb-0 mt-3 d-flex align-items-center justify-content-center'>
                            {userDetails && userDetails.fullName}
                            {isVerified && (
                                <span className='blue-tick static'>
                                    <BsPatchCheckFill size='1.3rem' className='ms-2 mb-1' />
                                </span>
                            )}
                            {isTopSeller && (
                                <span className='bg-primary badge mb-0 ms-2 text-xxs'>
                                    <i className='las la-trophy me-1 text-xxs'></i>
                                    Top Seller
                                </span>
                            )}
                            {web3Ctx?.account && web3Ctx?.account !== userDetails?.account && (
                                <Tooltip
                                    title={favList?.includes(web3Ctx?.account) ? 'Unfavourite' : 'Favourite'}
                                    position='top'
                                    trigger='mouseenter'
                                >
                                    {favList?.includes(web3Ctx?.account) ? (
                                        <button
                                            className='btn btn-link no-primary text-reset px-0 ms-2 shadow-0'
                                            onClick={unfavUser}
                                        >
                                            <AiFillHeart size='1.2rem' />
                                        </button>
                                    ) : (
                                        <button
                                            className='btn btn-link no-primary text-reset px-0 ms-2 shadow-0'
                                            onClick={favUser}
                                        >
                                            <AiOutlineHeart size='1.2rem' />
                                        </button>
                                    )}
                                </Tooltip>
                            )}
                        </h1>
                        <p className='text-muted mb-1'>{userDetails.role}</p>
                        <ul className='list-inline mb-4'>
                            {userDetails && userDetails.facebook !== '' && (
                                <li className='list-inline-item'>
                                    <a
                                        href={userDetails.facebook}
                                        rel='noopener noreferrer'
                                        className='user-social-link'
                                        target='_blank'
                                    >
                                        <i className='lab la-facebook-f'></i>
                                    </a>
                                </li>
                            )}
                            {userDetails && userDetails.twitter !== '' && (
                                <li className='list-inline-item'>
                                    <a
                                        href={userDetails.twitter}
                                        rel='noopener noreferrer'
                                        className='user-social-link'
                                        target='_blank'
                                    >
                                        <i className='lab la-twitter'></i>
                                    </a>
                                </li>
                            )}
                            {userDetails && userDetails.instagram !== '' && (
                                <li className='list-inline-item'>
                                    <a
                                        href={userDetails.instagram}
                                        rel='noopener noreferrer'
                                        className='user-social-link'
                                        target='_blank'
                                    >
                                        <i className='lab la-instagram'></i>
                                    </a>
                                </li>
                            )}
                            {userDetails && userDetails.dribbble !== '' && (
                                <li className='list-inline-item'>
                                    <a
                                        href={userDetails.dribbble}
                                        rel='noopener noreferrer'
                                        className='user-social-link'
                                        target='_blank'
                                    >
                                        <i className='lab la-dribbble'></i>
                                    </a>
                                </li>
                            )}
                        </ul>
                        <ul className='list-inline mb-0'>
                            <li className='list-inline-item px-2'>
                                <h3 className='mb-0 h3'>
                                    {updatedExploreItems &&
                                        updatedExploreItems?.filter((asset) => asset.owner === address)?.length}
                                </h3>
                                <p className='text-muted text-sm mb-0'>Owned NFTs</p>
                            </li>
                            <li className='list-inline-item px-2'>
                                <h3 className='mb-0 h3'>
                                    {updatedExploreItems &&
                                        createdAssets &&
                                        updatedExploreItems?.filter((asset) =>
                                            createdAssets?.some((item) => asset?.id === parseInt(item))
                                        )?.length}
                                </h3>
                                <p className='text-muted text-sm mb-0'>Created NFTs</p>
                            </li>
                            <li className='list-inline-item px-2'>
                                <h3 className='mb-0 h3'>{hasFav ? favList?.length : 0}</h3>
                                <p className='text-muted text-sm mb-0'>Favourites</p>
                            </li>
                            <li className='list-inline-item px-2'>
                                <h3 className='mb-0 h3'>{pageViews}</h3>
                                <p className='text-muted text-sm mb-0'>Page Views</p>
                            </li>
                            <li className='list-inline-item px-2'>
                                <h3 className='mb-0 h3'>
                                    {formatPrice(currentAuthors?.selling).toFixed(3)} {settings?.currency}
                                </h3>
                                <p className='text-muted text-sm mb-0'>Total Profits</p>
                            </li>
                        </ul>
                    </div>

                    <div className='py-5'>
                        <div className='row'>
                            <div className='col-lg-5 mx-auto'>
                                <div className='toggle-nav'>
                                    <button
                                        className={`toggle-nav-btn flex-fill ${
                                            isNavSelected === 'created' ? 'active' : null
                                        }`}
                                        onClick={() => setIsNavSelected('created')}
                                    >
                                        Created
                                    </button>
                                    <button
                                        className={`toggle-nav-btn flex-fill ${
                                            isNavSelected === 'collected' ? 'active' : null
                                        }`}
                                        onClick={() => setIsNavSelected('collected')}
                                    >
                                        Owned
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='pt-5'>
                        {collectionCtx.collection.length !== 0 && collectionCtx.totalSupply !== '0' && (
                            <header className='mb-4'>
                                <div className='row g-3 align-items-lg-end'>
                                    <div className='col-lg-8'>
                                        <ul className='list-inline mb-0'>
                                            <li className='list-inline-item mb-3 me-3'>
                                                <p className='text-sm fw-bold pe-lg-4 mb-3'>Filter by Category</p>
                                                <div className='input-icon flex-nowrap category-select'>
                                                    <div className='input-icon-text bg-none'>
                                                        <i className='las la-icons text-primary z-index-20'></i>
                                                    </div>
                                                    <Select
                                                        searchable={false}
                                                        options={categorySelectBox}
                                                        className='form-select rounded-xl border-gray-300 shadow-0 bg-white'
                                                        value={baseFilter}
                                                        onChange={(values) => {
                                                            setBaseFilter(values.map((el) => el.value).toString());
                                                        }}
                                                    />
                                                </div>
                                            </li>
                                            <li className='list-inline-item mb-3 me-3'>
                                                <p className='text-sm fw-bold pe-lg-4 mb-3'>Filter by Price</p>
                                                <div className='input-icon flex-nowrap category-select'>
                                                    <div className='input-icon-text bg-none'>
                                                        <i className='lab la-ethereum text-primary z-index-20'></i>
                                                    </div>
                                                    <Select
                                                        searchable={false}
                                                        options={priceOptions}
                                                        className='form-select rounded-xl border-gray-300 shadow-0 bg-white'
                                                        value={priceFilter}
                                                        onChange={(values) =>
                                                            setPriceFilter(values.map((el) => el.value).toString())
                                                        }
                                                    />
                                                </div>
                                            </li>
                                            <li className='list-inline-item mb-3'>
                                                <p className='text-sm fw-bold pe-lg-4 mb-3'>Sort By</p>
                                                <div className='input-icon flex-nowrap category-select'>
                                                    <div className='input-icon-text bg-none'>
                                                        <i className='lab la-ethereum text-primary z-index-20'></i>
                                                    </div>
                                                    <Select
                                                        searchable={false}
                                                        options={sorting}
                                                        className='form-select rounded-xl border-gray-300 shadow-0 bg-white'
                                                        value={sortFilter}
                                                        onChange={(values) =>
                                                            setSortFilter(values.map((el) => el.value).toString())
                                                        }
                                                    />
                                                </div>
                                            </li>
                                            <li className='list-inline-item mb-3'>
                                                <Tooltip
                                                    title='NFTs update in real time'
                                                    position='top'
                                                    trigger='mouseenter'
                                                >
                                                    <div
                                                        className='form-control bg-white px-4'
                                                        data-bs-toggle='tooltip'
                                                        data-bs-placement='top'
                                                        title='hey'
                                                        style={{ transform: 'translateY(3px)' }}
                                                    >
                                                        <div className='d-flex align-items-center'>
                                                            <div className='animated-bullet me-2'>
                                                                <div className='animated-bullet-inner'></div>
                                                            </div>
                                                            Live
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-lg-4 text-lg-end'>
                                        <ul className='list-inline p-0 mb-3'>
                                            <li className='list-inline-item'>
                                                <button
                                                    className={`btn btn-${
                                                        listView === 'list' ? 'primary' : 'secondary'
                                                    } btn-sm p-2`}
                                                    onClick={() => setListView('list')}
                                                >
                                                    <FaList size={18} />
                                                </button>
                                            </li>
                                            <li className='list-inline-item'>
                                                <button
                                                    className={`btn btn-${
                                                        listView === 'cube' ? 'primary' : 'secondary'
                                                    } btn-sm p-2`}
                                                    onClick={() => setListView('cube')}
                                                >
                                                    <FaTable size={18} />
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </header>
                        )}

                        {collectionCtx.collection.length !== 0 &&
                            collectionCtx.totalSupply !== '0' &&
                            isNavSelected === 'created' &&
                            updatedExploreItems &&
                            createdAssets && (
                                <>
                                    <div className='row mb-4'>
                                        <div className='col-lg-7'>
                                            <h2>NFTs created by {userDetails.fullName}</h2>
                                            <p className='lead text-muted'>
                                                You can see all NFTs created by {userDetails.fullName}
                                            </p>
                                        </div>
                                    </div>
                                    {listView === 'cube' ? (
                                        <div className='row row-cols-xxxl-5 row-cols-xxl-4 justify-content-center row-cols-xl-3 row-cols-lg-3 gy-5 mb-5'>
                                            {updatedExploreItems
                                                .filter((asset) =>
                                                    createdAssets.some((item) => asset.id === parseInt(item))
                                                )
                                                .slice(0, renderedItems)
                                                .map((NFT, key) => {
                                                    return (
                                                        <div className={`col ${NFT.category}`} key={key}>
                                                            <NftItem {...NFT} index={key} noAnimation={true} />
                                                        </div>
                                                    );
                                                })}

                                            {/* {auctionCtx.auctionsData
                                            .filter((auc) => auc.active === true)
                                            .filter((asset) =>
                                                createdAssets.some((item) => asset.tokenId === parseInt(item))
                                            )
                                            .map((AUC, key) => {
                                                return (
                                                    <div className='col' key={key}>
                                                        <AuctionItem {...AUC} nftKey={key} />
                                                    </div>
                                                );
                                            })} */}

                                            {updatedExploreItems?.filter((asset) =>
                                                createdAssets.some((item) => asset.id === parseInt(item))
                                            )?.length > renderedItems && (
                                                <div className='text-center pt-4 col-12'>
                                                    <button
                                                        className='btn btn-dark'
                                                        type='button'
                                                        onClick={() => setRenderedItems(renderedItems + 5)}
                                                    >
                                                        Load More
                                                    </button>
                                                </div>
                                            )}

                                            {collectionCtx.collection.filter((asset) =>
                                                createdAssets.some((item) => asset.id === parseInt(item))
                                            ).length === 0 && <h4>{userDetails.fullName} Hasn't created any NFTs</h4>}
                                        </div>
                                    ) : (
                                        <UserGalleryTable
                                            data={updatedExploreItems?.filter((asset) =>
                                                createdAssets.some((item) => asset.id === parseInt(item))
                                            )}
                                        />
                                    )}
                                </>
                            )}

                        {collectionCtx.collection.length !== 0 &&
                            collectionCtx.totalSupply !== '0' &&
                            isNavSelected === 'collected' &&
                            updatedExploreItems &&
                            createdAssets && (
                                <>
                                    <div className='row mb-4'>
                                        <div className='col-lg-7'>
                                            <h2>NFTs owned by {userDetails.fullName}</h2>
                                            <p className='lead text-muted'>
                                                You can see all NFTs owned by {userDetails.fullName}
                                            </p>
                                        </div>
                                    </div>
                                    {listView === 'cube' ? (
                                        <div className='row row-cols-xxxl-5 row-cols-xxl-4 justify-content-center row-cols-xl-3 row-cols-lg-3 gy-5 mb-5'>
                                            {updatedExploreItems
                                                .filter((asset) => asset.owner === address)
                                                .slice(0, renderedItems)
                                                .map((NFT, key) => {
                                                    return (
                                                        <div className={`col ${NFT.category}`} key={key}>
                                                            <NftItem {...NFT} index={key} noAnimation={true} />
                                                        </div>
                                                    );
                                                })}

                                            {/* {auctionCtx.auctionsData
                                            .filter((auc) => auc.active === true)
                                            .filter((asset) => asset.user === address)
                                            .map((AUC, key) => {
                                                return (
                                                    <div className='col' key={key}>
                                                        <AuctionItem {...AUC} nftKey={key} />
                                                    </div>
                                                );
                                            })} */}

                                            {updatedExploreItems?.filter((asset) => asset.owner === address)?.length >
                                                renderedItems && (
                                                <div className='text-center pt-4 col-12'>
                                                    <button
                                                        className='btn btn-dark'
                                                        type='button'
                                                        onClick={() => setRenderedItems(renderedItems + 5)}
                                                    >
                                                        Load More
                                                    </button>
                                                </div>
                                            )}

                                            {collectionCtx.collection.filter((asset) => asset.user === address).length +
                                                collectionCtx.collection.filter((asset) => asset.owner === address)
                                                    .length ===
                                                0 && <h4>{userDetails.fullName} doen't own any NFTs</h4>}
                                        </div>
                                    ) : (
                                        <UserGalleryTable
                                            data={updatedExploreItems.filter((asset) => asset.owner === address)}
                                        />
                                    )}
                                </>
                            )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default UserGalleryPage;
