import React, { useEffect } from 'react';
import { settings } from '../../helpers/settings';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';

// COMPONENTS
import PageBanner from '../../components/general/PageBanner';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';

function ContactPage() {
    /*** ---------------------------------------- */
    //      CHANGE PAGE TITLE
    /*** ---------------------------------------- */
    useEffect(() => {
        document.title = `Contact Us | ${settings.UISettings.marketplaceBrandName}`;
    });

    const markerIcon = new L.icon({
        iconUrl: '/marker.png',
        iconSize: [50, 50],
    });

    return (
        <>
            <PageBanner heading={'Contact Us'} />
            <div className='shadow' data-aos='fade-up' data-aos-delay='100'>
                <MapContainer
                    doubleClickZoom={true}
                    id='mapId'
                    zoom={13}
                    center={settings.mapCenter}
                    scrollWheelZoom={false}
                >
                    <TileLayer
                        url='https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.{ext}'
                        attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        ext='png'
                        subdomains='abcd'
                    />
                    <Marker position={settings.mapCenter} icon={markerIcon}></Marker>
                </MapContainer>
            </div>
            <section className='py-5'>
                <div className='container py-5'>
                    <div className='row g-5'>
                        <ContactInfo gridWidth='col-lg-8 mx-auto' />
                        <ContactForm gridWidth='col-lg-8 mx-auto' />
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContactPage;
