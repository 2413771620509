import React from 'react';
import { Link } from 'react-router-dom';

function Category({ category }) {
    return (
        <p className='text-sm text-white badge bg-primary fw-normal d-flex align-items-center mb-0'>
            {category ? (
                <Link className='text-white' to={`/categories/${category}`}>
                    <span className='text-white lh-reset'>
                        {category && category?.charAt(0).toUpperCase() + category?.slice(1)}
                    </span>
                </Link>
            ) : (
                <span className='text-white'>No Cateogry</span>
            )}
        </p>
    );
}

export default Category;
