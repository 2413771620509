import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { settings } from '../../helpers/settings';
import Select from 'react-dropdown-select';
import { Tooltip } from 'react-tippy';

// HOOKS
import useMarketplace from '../../hooks/useMarketplace';
import useAuctions from '../../hooks/useAuctions';
import useCollection from '../../hooks/useCollection';
import { FaList, FaTable } from 'react-icons/fa';

// COMPONENTS
import PageBanner from '../../components/general/PageBanner';
import NftItem from '../../components/general/NftItem';
import AuctionItem from '../../components/general/AuctionItem';
import FullScreenLoader from '../../components/general/FullScreenLoader';
import MetaMaskLoader from '../../components/general/MetaMaskLoader';
import { useMemo } from 'react';
import CategoryListTable from './CategoryListTable';

// SELECT OPTIONS
const priceOptions = [
    { label: 'All', value: 'all' },
    { label: 'Only on Sale', value: 'saleOnly' },
    { label: 'Not for Sale', value: 'notForSale' },
];

const sorting = [
    { label: 'Newest First', value: 'newest' },
    { label: 'Oldest First', value: 'oldest' },
    { label: 'Highest Price', value: 'highPrice' },
    { label: 'Lowest Price', value: 'lowPrice' },
];

function CategoryPage() {
    const collectionCtx = useCollection();
    const marketplaceCtx = useMarketplace();
    const auctionCtx = useAuctions();

    const [sortFilter, setSortFilter] = useState('newest');
    const [priceFilter, setPriceFilter] = useState('all');
    const [withPriceCollection, setWithPriceCollection] = useState([]);
    const { category } = useParams();
    const [renderedItems, setRenderedItems] = useState(15);
    const [listView, setListView] = useState('cube');
    const categoryItems = useMemo(() => {
        return collectionCtx.collection?.filter((nft) => nft?.category === category);
    }, [collectionCtx.collection, category]);

    /*** ------------------------------------------------ */
    //      CHANGE PAGE TITLE
    /*** ------------------------------------------------ */
    useEffect(() => {
        document.title = `${category} | ${settings.UISettings.marketplaceBrandName}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*** =============================================== */
    //      MERGE NFT COLLECTION WITH NFT OFFERS
    /*** =============================================== */
    useEffect(() => {
        if (marketplaceCtx.contract && collectionCtx.contract && categoryItems.length > 0) {
            setWithPriceCollection(categoryItems);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketplaceCtx.offers, collectionCtx.contract, marketplaceCtx.contract, categoryItems, auctionCtx.auctions]);

    /* --------------------------------------------- 
          GET FEATURED NFTS
    --------------------------------------------- */
    const featuredFromCategory = useMemo(() => {
        return collectionCtx.collection
            ?.filter(
                (nft) =>
                    !auctionCtx.auctions.filter((auc) => auc.isActive === true).some((auc) => nft.id === auc.tokenId)
            )
            ?.filter((el) => el.isApproved)
            ?.filter((el) => el?.isPromoted && el?.category === category)
            ?.sort((a, b) => b?.dateCreated - a?.dateCreated);
    }, [category, collectionCtx.collection, auctionCtx.auctions]);

    /*** =============================================== */
    //      FORMATE ACCORDING TO SALE STATUS
    /*** =============================================== */
    const priceFilteredItems = useMemo(() => {
        if (priceFilter === 'saleOnly') {
            return categoryItems.filter((item) => item.hasOffer);
        } else if (priceFilter === 'notForSale') {
            return categoryItems.filter((item) => !item.hasOffer);
        } else {
            return categoryItems;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortFilter, priceFilter, categoryItems, collectionCtx.collection]);

    /*** =============================================== */
    //      SORTING COLLECTION
    /*** =============================================== */
    const updatedExploreItems = useMemo(() => {
        if (sortFilter === 'newest') {
            return priceFilteredItems.sort((a, b) => {
                return Number(b.dateCreated) - Number(a.dateCreated);
            });
        } else if (sortFilter === 'oldest') {
            return priceFilteredItems.sort((a, b) => {
                return Number(a.dateCreated) - Number(b.dateCreated);
            });
        } else if (sortFilter === 'highPrice') {
            return withPriceCollection
                .filter((x) => priceFilteredItems.some((y) => x.id === y.id))
                .filter((el) => el.price > 0)
                .sort((a, b) => {
                    return b.price - a.price;
                });
        } else if (sortFilter === 'lowPrice') {
            return withPriceCollection
                .filter((x) => priceFilteredItems.some((y) => x.id === y.id))
                .filter((el) => el.price > 0)
                .sort((a, b) => {
                    return a.price - b.price;
                });
        } else {
            return priceFilteredItems;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortFilter, priceFilteredItems, priceFilter]);

    return (
        <>
            {auctionCtx.fetchingLoading ? <FullScreenLoader heading='Loading' /> : null}
            {auctionCtx.auctionTransactionLoading ? <FullScreenLoader heading='loading' /> : null}
            {marketplaceCtx.mktIsLoading ? <FullScreenLoader heading='loading' /> : null}
            {collectionCtx.nftTransactionLoading ? <MetaMaskLoader /> : null}
            <PageBanner heading={`${category?.charAt(0).toUpperCase() + category?.slice(1)} NFTs`} />
            {featuredFromCategory && featuredFromCategory?.length > 0 && (
                <section className='py-5'>
                    <div className='container py-5'>
                        <header className='mb-4 text-center'>
                            <h3>Our Featured Items</h3>
                        </header>
                        <div className='row justify-content-center row-cols-xxxl-5 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 gy-5 mb-5'>
                            {featuredFromCategory.slice(0, 10).map((NFT, key) => {
                                return (
                                    <div className={`col mix ${NFT.category}`} key={key}>
                                        <NftItem {...NFT} index={key} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            )}
            <section className='py-5 bg-light'>
                <div className='container py-5'>
                    <header className='mb-4 text-center'>
                        <h3>All from this category</h3>
                    </header>
                    {categoryItems?.length > 0 && (
                        <header className='mb-2'>
                            <div className='row g-3 align-items-lg-end'>
                                <div className='col-lg-8'>
                                    <ul className='list-inline mb-0'>
                                        <li className='list-inline-item mb-3 me-3'>
                                            <p className='text-sm fw-bold pe-lg-4 mb-3'>Filter by Price</p>
                                            <div className='input-icon flex-nowrap category-select'>
                                                <div className='input-icon-text bg-none'>
                                                    <i className='lab la-ethereum text-primary z-index-20'></i>
                                                </div>
                                                <Select
                                                    searchable={false}
                                                    options={priceOptions}
                                                    className='form-select rounded-xl border-gray-300 shadow-0 bg-white'
                                                    value={priceFilter}
                                                    onChange={(values) =>
                                                        setPriceFilter(values.map((el) => el.value).toString())
                                                    }
                                                />
                                            </div>
                                        </li>
                                        <li className='list-inline-item mb-3'>
                                            <p className='text-sm fw-bold pe-lg-4 mb-3'>Sort By</p>
                                            <div className='input-icon flex-nowrap category-select'>
                                                <div className='input-icon-text bg-none'>
                                                    <i className='lab la-ethereum text-primary z-index-20'></i>
                                                </div>
                                                <Select
                                                    searchable={false}
                                                    options={sorting}
                                                    className='form-select rounded-xl border-gray-300 shadow-0 bg-white'
                                                    value={sortFilter}
                                                    onChange={(values) =>
                                                        setSortFilter(values.map((el) => el.value).toString())
                                                    }
                                                />
                                            </div>
                                        </li>
                                        <li className='list-inline-item mb-3'>
                                            <Tooltip
                                                title='NFTs update in real time'
                                                position='top'
                                                trigger='mouseenter'
                                            >
                                                <div
                                                    className='form-control bg-white px-4'
                                                    data-bs-toggle='tooltip'
                                                    data-bs-placement='top'
                                                    title='hey'
                                                    style={{ transform: 'translateY(3px)' }}
                                                >
                                                    <div className='d-flex align-items-center'>
                                                        <div className='animated-bullet me-2'>
                                                            <div className='animated-bullet-inner'></div>
                                                        </div>
                                                        Live
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-lg-4 text-lg-end'>
                                    <ul className='list-inline p-0 mb-3'>
                                        <li className='list-inline-item'>
                                            <button
                                                className={`btn btn-${
                                                    listView === 'list' ? 'primary' : 'secondary'
                                                } btn-sm p-2`}
                                                onClick={() => setListView('list')}
                                            >
                                                <FaList size={18} />
                                            </button>
                                        </li>
                                        <li className='list-inline-item'>
                                            <button
                                                className={`btn btn-${
                                                    listView === 'cube' ? 'primary' : 'secondary'
                                                } btn-sm p-2`}
                                                onClick={() => setListView('cube')}
                                            >
                                                <FaTable size={18} />
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </header>
                    )}

                    {collectionCtx.collection.length !== 0 &&
                        collectionCtx.totalSupply !== '0' &&
                        (listView === 'cube' ? (
                            <div className='row row-cols-xxxl-5 row-cols-xxl-4 justify-content-center row-cols-xl-3 row-cols-lg-3 gy-5 mb-5'>
                                {updatedExploreItems
                                    .filter(
                                        (nft) =>
                                            !auctionCtx.auctions
                                                .filter((auc) => auc.isActive === true)
                                                .some((auc) => nft.id === auc.tokenId)
                                    )
                                    .filter((el) => el.isApproved)
                                    .slice(0, renderedItems)
                                    .map((NFT, key) => {
                                        return (
                                            <div className={`col mix ${NFT.category}`} key={key}>
                                                <NftItem {...NFT} index={key} />
                                            </div>
                                        );
                                    })}

                                {auctionCtx.auctionsData
                                    .filter((auc) => auc.active === true && auc.category === category)
                                    .map((AUC, key) => {
                                        return (
                                            <div className='col' key={key}>
                                                <AuctionItem {...AUC} nftKey={key} />
                                            </div>
                                        );
                                    })}
                                <div className='col-12 text-center mt-5'>
                                    {updatedExploreItems.length > renderedItems && (
                                        <div className='text-center pt-4'>
                                            <button
                                                className='btn btn-dark'
                                                type='button'
                                                onClick={() => setRenderedItems(renderedItems + 5)}
                                            >
                                                Load More
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <CategoryListTable
                                data={updatedExploreItems
                                    ?.filter(
                                        (nft) =>
                                            !auctionCtx.auctions
                                                .filter((auc) => auc.isActive === true)
                                                .some((auc) => nft.id === auc.tokenId)
                                    )
                                    .filter((el) => el.isApproved)}
                            />
                        ))}
                    {collectionCtx.collection.length !== 0 &&
                        collectionCtx.totalSupply !== '0' &&
                        updatedExploreItems.length === 0 && (
                            <>
                                <h4 className='text-center'>There're no NFTs match your filter</h4>
                            </>
                        )}
                    {categoryItems.filter((el) => el.category === category).filter((el) => el.isApproved).length ===
                        0 && (
                        <div className='text-center'>
                            <h4 className='text-center'>There're no NFTs at the moment</h4>
                            <p className='text-muted mb-3'>
                                Once there'll be NFTs that match is category we'll render them here
                            </p>
                            <Link className='btn btn-gradient-primary' to='/'>
                                Return Home
                            </Link>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}

export default CategoryPage;
