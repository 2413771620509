import React from 'react';

function Loader() {
    return (
        <div className='d-flex justify-content-center'>
            <div className='loading'>
                <span></span>
            </div>
        </div>
    );
}

export default Loader;
