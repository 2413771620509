import React from 'react';
import { motion } from 'framer-motion/dist/es/index';
import { useForm as reportFormSubmit } from '@formspree/react';
import { useForm } from 'react-hook-form';
import { settings } from '../../helpers/settings';
import { useToasts } from 'react-toast-notifications';
import useMarketplace from '../../hooks/useMarketplace';
import { useEffect } from 'react';

const fullScreenLoaderStyle = {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: '0',
    left: '0',
    background: 'rgba(0, 0, 0, 0.87)',
    zIndex: '9999',
};

function ReportNFT({ closeModal }) {
    const [state, handleFormSubmit] = reportFormSubmit(settings.UISettings.nftReportFormId);
    const { addToast } = useToasts();
    const { setReportForm, reportedNftId, setNFTLink } = useMarketplace();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    function reportNftHandler(data) {
        console.log(data);
        handleFormSubmit({ nftLink: `https://420.work/assets/${reportedNftId}`, ...data });
    }

    useEffect(() => {
        if (state?.succeeded) {
            addToast('Great! Your report has been sent', {
                appearance: 'success',
            });
            setReportForm(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    console.log(state);

    return (
        <>
            <motion.div
                className='d-flex align-items-center justify-content-center'
                style={fullScreenLoaderStyle}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.05 }}
            >
                <div className='row w-100'>
                    <div className='col-lg-8 mx-auto' data-aos='fade-up' data-aos-delay='100'>
                        <div className='card px-lg-5 rounded-xl' style={{ maxHeight: '95vh', overflowY: 'auto' }}>
                            <div className='card-body px-4 py-5'>
                                <header className='text-center mb-4'>
                                    <p className='h2'>Report NFT</p>
                                    <p className='lead mb-3'>
                                        If you feel that this NFT contains something inappropriate, please let know
                                    </p>
                                </header>

                                <form noValidate onSubmit={handleSubmit(reportNftHandler)}>
                                    <div className='row gy-4'>
                                        <div className='col-lg-6'>
                                            <label className='form-label'>Full Name</label>
                                            <input
                                                type='text'
                                                className={`${
                                                    errors.fullName ? 'is-invalid' : null
                                                } form-control bg-white`}
                                                placeholder='e.g. Jason Doe'
                                                {...register('fullName', {
                                                    required: {
                                                        value: true,
                                                        message: 'Please enter your fullname',
                                                    },
                                                })}
                                            />
                                            {errors.fullName && (
                                                <span className='invalid-feedback'>{errors?.fullName?.message}</span>
                                            )}
                                        </div>
                                        <div className='col-lg-6'>
                                            <label className='form-label'>Email Address</label>
                                            <input
                                                type='email'
                                                className={`${
                                                    errors.email ? 'is-invalid' : null
                                                } form-control bg-white`}
                                                placeholder='e.g. jason@company.com'
                                                {...register('email', {
                                                    required: {
                                                        value: true,
                                                        message: 'Please enter your email',
                                                    },
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: 'Please enter a valid email format',
                                                    },
                                                })}
                                            />
                                            {errors.email && (
                                                <span className='invalid-feedback'>{errors?.email?.message}</span>
                                            )}
                                        </div>
                                        <div className='col-lg-12'>
                                            <label className='form-label'>Description</label>
                                            <textarea
                                                type='description'
                                                rows='8'
                                                className={`${
                                                    errors.description ? 'is-invalid' : null
                                                } form-control bg-white`}
                                                placeholder='Provide a brief about your report'
                                                {...register('description', {
                                                    required: {
                                                        value: true,
                                                        message: 'Please provide a report description',
                                                    },
                                                })}
                                            ></textarea>
                                            {errors.description && (
                                                <span className='invalid-feedback'>{errors?.description?.message}</span>
                                            )}
                                        </div>

                                        <div className='col-12'>
                                            <ul className='list-inline mb-0'>
                                                <li className='list-inline-item m-1'>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary'
                                                        onClick={closeModal}
                                                    >
                                                        Report
                                                    </button>
                                                </li>
                                                <li className='list-inline-item m-1'>
                                                    <button
                                                        className='btn btn-dark'
                                                        type='button'
                                                        onClick={() => {
                                                            setReportForm(false);
                                                            setNFTLink(null);
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    );
}

export default ReportNFT;
