import React, { useEffect } from 'react';
import { settings } from '../../helpers/settings';
import { techQuestions } from './FAQItems';

// COMPONENTS
import PgaeBanner from '../../components/general/PageBanner';

function FAQsPage() {
    /*** --------------------------------------- */
    //      CHANGE PAGE TITLE
    /*** --------------------------------------- */
    useEffect(() => {
        document.title = `FAQ | ${settings.UISettings.marketplaceBrandName}`;
    }, []);

    return (
        <>
            <PgaeBanner heading='Frequently Asked Questions' />

            <section className='py-5'>
                <div className='container py-5'>
                    <div className='row g-5'>
                        <div className='col-lg-8 mx-auto order-2 order-lg-1'>
                            <h2 className='mb-5 text-center'>Technical Questions</h2>
                            <div className='accordion' id='accordionExample'>
                                {techQuestions.map((el, index) => {
                                    return (
                                        <div className='accordion-item mb-3' key={index}>
                                            <h2 className='accordion-header' id={`heading${index + 1}`}>
                                                <button
                                                    className={`accordion-button py-4 shadow-0 fw-bold ${
                                                        index === 0 ? '' : 'collapsed'
                                                    }`}
                                                    type='button'
                                                    data-bs-toggle='collapse'
                                                    data-bs-target={`#collapse${index + 1}`}
                                                    aria-expanded={index === 0 ? true : false}
                                                    aria-controls={`collapse${index + 1}`}
                                                >
                                                    {el.question}
                                                </button>
                                            </h2>
                                            <div
                                                id={`collapse${index + 1}`}
                                                className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                                aria-labelledby={`heading${index + 1}`}
                                                data-bs-parent='#accordionExample'
                                            >
                                                <div className='accordion-body'>{el.answer}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FAQsPage;
