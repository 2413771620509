import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BiSearch } from 'react-icons/bi';
import useMarketplace from '../../hooks/useMarketplace';

function HeaderSearch() {
    const [searchQuery, setSearchQuery] = useState('');
    const history = useHistory();
    const { themeMode } = useMarketplace();

    function handleSearchNavigation(e) {
        e.preventDefault();
        if (searchQuery?.length > 0) {
            history.push(`/search/${searchQuery}`);
            setTimeout(() => {
                setSearchQuery('');
            }, 100);
        }
    }

    return (
        <>
            <div className='dropdown w-100' style={{ maxWidth: '67rem' }} onClick={(e) => e.stopPropagation()}>
                <div className='header-search-bar'>
                    <form onSubmit={handleSearchNavigation}>
                        <input
                            type='text'
                            className='form-control'
                            placeholder='Search NFTs, Collections & Artists'
                            onChange={(e) => setSearchQuery(e.target.value)}
                            value={searchQuery}
                        />
                        <button className='header-search-btn' type='submit'>
                            <BiSearch size={20} className={themeMode === 'light' ? 'text-dark' : 'text-white'} />
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default HeaderSearch;
