import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { categoryOptions } from '../../helpers/constants';
import { settings } from '../../helpers/settings';
import ReactPlayer from 'react-player';

// HOOKS
import useMarketplace from '../../hooks/useMarketplace';
import useAuctions from '../../hooks/useAuctions';
import useCollection from '../../hooks/useCollection';

// COMPONENTS
import HomeBanner from './HomeBanner';
import NftItem from '../../components/general/NftItem';
import AuctionItem from '../../components/general/AuctionItem';
import NoDataAlert from '../../components/general/NoDataAlert';
import TopSellers from './TopSellers';
import Loader from '../../components/general/Loader';
import MetaMaskLoader from '../../components/general/MetaMaskLoader';

function HomePage({ topSellers }) {
    const collectionCtx = useCollection();
    const marketplaceCtx = useMarketplace();
    const auctionCtx = useAuctions();

    /*** ---------------------------------------------- */
    //      CHANGE PAGE TITLE
    /*** ---------------------------------------------- */
    useEffect(() => {
        document.title = settings.UISettings.marketplaceBrandName;
    }, []);

    return (
        <>
            <HomeBanner />
            {collectionCtx.nftTransactionLoading ? <MetaMaskLoader /> : null}
            {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}

            {/* MARKETPLACE FEATURED ITEMS */}
            <section className='pb-5'>
                <div className='container pb-5 z-index-20'>
                    <header className='mb-4'>
                        <div className='row'>
                            <div className='col-lg-6 mx-auto text-center'>
                                <h2 data-aos='fade-up' data-aos-delay='100'>
                                    Featured this Week
                                </h2>
                                <p className='text-muted lead mb-0' data-aos='fade-up' data-aos-delay='200'>
                                    We're excited to share our ten favorite non-fungible tokens this month!
                                </p>
                            </div>
                        </div>
                    </header>

                    {collectionCtx.totalSupply === '0' && !collectionCtx.nftIsLoading ? (
                        <div className='col-lg-9 mx-auto'>
                            <NoDataAlert
                                heading="There're no NFTs at the moment."
                                subheading='Try to mint some assets to see how are we rendering them.'
                                customClass='justify-content-center'
                                aos='fade-up'
                                aosDelay='300'
                            />
                        </div>
                    ) : null}

                    {collectionCtx.collection.length === 0 && collectionCtx.totalSupply !== '0' ? <Loader /> : null}

                    <div className='row row-cols-xxxl-5 row-cols-xxl-4 justify-content-center row-cols-xl-3 row-cols-lg-3 gy-5 mb-5'>
                        {collectionCtx.collection
                            .filter(
                                (nft) =>
                                    !auctionCtx.auctions
                                        .filter((auc) => auc.isActive === true)
                                        .some((auc) => nft.id === auc.tokenId)
                            )
                            .filter((nft) => nft.isApproved === true)
                            .filter((nft) => nft.isPromoted === true)
                            .sort((a, b) => b.dateCreated - a.dateCreated)
                            .slice(0, 5)
                            .map((NFT, key) => {
                                return (
                                    <div key={key} className='col'>
                                        <NftItem {...NFT} index={key} />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </section>

            {/* BROWSE BY CATEGORY */}
            <section className='py-5 bg-light'>
                <div className='container py-4'>
                    <header className='mb-5'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <h2 data-aos='fade-up' data-aos-delay='100'>
                                    Categories
                                </h2>
                                <p className='text-muted lead mb-0' data-aos='fade-up' data-aos-delay='200'>
                                    Browse what's trending or find the rarest NFTs by category.
                                </p>
                            </div>
                        </div>
                    </header>

                    <div className='row gy-4'>
                        {categoryOptions.map((el, i) => {
                            return (
                                <div
                                    className='col-xl-2 col-lg-4 col-6'
                                    key={i}
                                    data-aos='fade-left'
                                    data-aos-delay={(i + 1) * 100}
                                >
                                    <div className='card card-hover-minimal border-0 rounded-xl htw-card'>
                                        <div className='card-body text-center py-4'>
                                            <i
                                                className={`text-primary mb-2 ${el.icon}`}
                                                style={{ fontSize: '2rem' }}
                                            ></i>
                                            <Link className='text-reset stretched-link' to={`/categories/${el.value}`}>
                                                <h6 className='fw-normal'>{el.label}</h6>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            {/* NFT ITEMS */}
            <section className='py-5 position-relative'>
                <div className='container pt-5 z-index-20'>
                    <header className='mb-4'>
                        <div className='row'>
                            <div className='col-lg-6 mx-auto text-center'>
                                <h2 data-aos='fade-up' data-aos-delay='100'>
                                    Latest NFTs
                                </h2>
                                <p className='text-muted lead mb-0' data-aos='fade-up' data-aos-delay='200'>
                                    You're in for a treat. There are a bunch of new NFTs that you might like. Check them
                                    out!
                                </p>
                            </div>
                        </div>
                    </header>

                    {collectionCtx.totalSupply === '0' && !collectionCtx.nftIsLoading ? (
                        <div className='col-lg-9 mx-auto'>
                            <NoDataAlert
                                heading="There're no NFTs at the moment."
                                subheading='Try to mint some assets to see how are we rendering them.'
                                customClass='justify-content-center'
                                aos='fade-up'
                                aosDelay='300'
                            />
                        </div>
                    ) : null}

                    {collectionCtx.collection.length === 0 && collectionCtx.totalSupply !== '0' ? <Loader /> : null}

                    <div className='row row-cols-xxxl-5 row-cols-xxl-4 justify-content-center row-cols-xl-3 row-cols-lg-3 gy-5 mb-5'>
                        {collectionCtx.collection
                            .filter(
                                (nft) =>
                                    !auctionCtx.auctions
                                        .filter((auc) => auc.isActive === true)
                                        .some((auc) => nft.id === auc.tokenId)
                            )
                            .filter((nft) => nft.isApproved === true)
                            .sort((a, b) => b.dateCreated - a.dateCreated)
                            .slice(0, 10)
                            .map((NFT, key) => {
                                return (
                                    <div className='col' key={key}>
                                        <NftItem {...NFT} />
                                    </div>
                                );
                            })}
                    </div>
                </div>
                {marketplaceCtx.themeMode === 'dark' && <div className='glow'></div>}
            </section>

            <TopSellers
                topSellers={topSellers}
                title='Top Sellers'
                description='Bestselling NFT authors on our marketplace.'
            />

            <section className='py-5 bg-light'>
                <div className='container py-5'>
                    <div className='row gy-5 align-items-center'>
                        <div className='col-lg-6'>
                            <h2 data-aos='fade-right' data-aos-delay='100'>
                                How it works
                            </h2>
                            <p className='text-muted lead mb-4' data-aos='fade-up' data-aos-delay='200'>
                                Feel free to sign up and dive in if you want to get into the nuance of minting, buying,
                                or selling NFTs.
                            </p>

                            <div className='d-flex mb-4' data-aos='fade-right' data-aos-delay='150'>
                                <div className='icon-animated rounded-xl bg-primary flex-shrink-0'>
                                    <i className='las la-wallet text-white'></i>
                                </div>
                                <div className='ms-3'>
                                    <h5>Connect your wallet</h5>
                                    <p className='text-muted text-sm mb-0'>
                                        MetaMask is an easy-to-use browser extension that allows you to interface with
                                        Ethereum applications like Elysium.
                                    </p>
                                </div>
                            </div>
                            <div className='mb-4 d-flex' data-aos='fade-right' data-aos-delay='200'>
                                <div className='icon-animated rounded-xl bg-primary flex-shrink-0'>
                                    <i className='las la-rocket text-white'></i>
                                </div>
                                <div className='ms-3'>
                                    <h5>Mint & Buy and Sell NFTs</h5>
                                    <p className='text-muted text-sm mb-2'>
                                        NFTs can represent anything from art to music to collectibles and more. NFTs
                                        allow you to own digital items in the same way that you would own physical
                                        items, like trading cards or stamps.
                                    </p>
                                </div>
                            </div>
                            <div className='mb-0 d-flex' data-aos='fade-right' data-aos-delay='250'>
                                <div className='icon-animated rounded-xl bg-primary flex-shrink-0'>
                                    <i className='lab la-ethereum text-white'></i>
                                </div>
                                <div className='ms-3'>
                                    <h5>Purchase & Transfer</h5>
                                    <p className='text-muted text-sm mb-0'>
                                        When buyers purchase the NFT token, it gets automatically transferred to them by
                                        the marketplace.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 ms-auto' data-aos='fade-left' data-aos-delay='100'>
                            <img src='/ils-3.png' alt='Illustration' className='img-fluid w-100' />
                        </div>
                    </div>
                </div>
            </section>

            {/* RECENT AUCTIONS */}
            <section className='py-5'>
                <div className='container py-5'>
                    <header className='mb-4'>
                        <div className='row'>
                            <div className='col-lg-6 mx-auto text-center'>
                                <h2 data-aos='fade-up' data-aos-delay='100'>
                                    Recent Auctions
                                </h2>
                                <p className='text-muted lead mb-0' data-aos='fade-up' data-aos-delay='200'>
                                    Get this rare limited edition collectible before someone else does!
                                </p>
                            </div>
                        </div>
                    </header>

                    {auctionCtx.auctionsData.filter((auc) => auc.active === true).length === 0 &&
                    !collectionCtx.nftIsLoading ? (
                        <div className='col-lg-9 mx-auto'>
                            <NoDataAlert
                                heading="There're no Auctions at the moment."
                                subheading='Once someone has created an auction, you should find it here.'
                                customClass='justify-content-center'
                                aos='fade-up'
                                aosDelay='300'
                            />
                        </div>
                    ) : null}

                    {collectionCtx.nftIsLoading ? <Loader /> : null}

                    <div className='row row-cols-xxxl-5 row-cols-xxl-4 justify-content-center row-cols-xl-3 row-cols-lg-3 gy-5 mb-5'>
                        {auctionCtx.auctionsData
                            .filter((auc) => auc.active === true)
                            .map((AUC, key) => {
                                return (
                                    <div key={key} className='col'>
                                        <AuctionItem {...AUC} nftKey={key} />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </section>

            {/* MEET THE NFT MARKETPLACE */}
            <section className='pb-5'>
                <div className='container pb-5'>
                    <header className='mb-4'>
                        <div className='row'>
                            <div className='col-lg-6 mx-auto text-center'>
                                <h2 data-aos='fade-up' data-aos-delay='100'>
                                    Meet NFT Marketplace
                                </h2>
                                <p className='text-muted lead mb-0' data-aos='fade-up' data-aos-delay='200'>
                                    The NFT marketplace with Cannabis for everyone
                                </p>
                            </div>
                        </div>
                    </header>

                    <div className='row'>
                        <div className='col-lg-8 mx-auto' data-aos='fade-up'>
                            <ReactPlayer
                                url={settings.UISettings.videoBannerUrl}
                                controls={true}
                                responsive='true'
                                width='100%'
                                height='500px'
                                color='2f925e'
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomePage;
