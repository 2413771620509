import React from 'react';
import PageBanner from '../../components/general/PageBanner';
import { RiInformationFill } from 'react-icons/ri';

function PrivacyPolicyPage() {
    return (
        <>
            <PageBanner heading='Privacy Policy' />

            <section className='py-5'>
                <div className='container py-5'>
                    <div className='row g-5'>
                        <p className='mt-0'>
                            <RiInformationFill className='me-1 text-primary' size='1.3rem' /> Last Updated: October 10,
                            2022
                        </p>
                        <p className='mt-0'>
                            QOOMED UNIPESSOAL LDA. d/b/a 420.Work (“420Work,” “we”, “us”, or “our”) is committed to
                            protecting your privacy. We have prepared this Privacy Policy to describe to you our
                            practices regarding the Personal Data (as defined below) we collect, use, and share in
                            connection with the 420Work website, mobile app, and other software provided on or in
                            connection with our services, as described in our Terms of Service (collectively, the
                            “Service”). “NFT” in this Privacy Policy means a non-fungible token or similar digital item
                            implemented on a blockchain (such as the Ethereum blockchain), which uses smart contracts to
                            link to or otherwise be associated with certain content or data.
                        </p>
                        <ol className='mt-0'>
                            <li>
                                <strong>Types of Data We Collect.</strong> “Personal Data” means data that allows
                                someone to identify you individually, including, for example, your name, email address,
                                as well as any other non-public information about you that is associated with or linked
                                to any of the foregoing. “Anonymous Data” means data, including aggregated and
                                de-identified data, that is not associated with or linked to your Personal Data;
                                Anonymous Data does not, by itself, permit the identification of individual persons. We
                                collect Personal Data and Anonymous Data as described below.
                                <ol className='mb-4' type='a'>
                                    <li>
                                        <strong>Information You Provide Us</strong>
                                        <ol className='mb-4' type='i'>
                                            <li>
                                                When you use our Service, update your account profile, or contact us, we
                                                may collect Personal Data from you, such as email address, first and
                                                last name, user name, and other information you provide. We also collect
                                                your blockchain address, which may become associated with Personal Data
                                                when you use our Service.
                                            </li>
                                            <li>
                                                Our Service lets you store preferences like how your content is
                                                displayed, notification settings, and favorites. We may associate these
                                                choices with your ID, browser, or mobile device.
                                            </li>
                                            <li>
                                                If you provide us with feedback or contact us, we will collect your name
                                                and contact information, as well as any other content included in the
                                                message.
                                            </li>
                                            <li>
                                                We may also collect Personal Data at other points in our Service where
                                                you voluntarily provide it or where we state that Personal Data is being
                                                collected.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>Information Collected via Technology</strong> As you navigate through
                                        and interact with our Service, we may use automatic data collection technologies
                                        to collect certain information about your equipment, browsing actions, and
                                        patterns, including:
                                        <ol className='mb-4' type='i'>
                                            <li>
                                                Information Collected by Our Servers. To provide our Service and make it
                                                more useful to you, we (or a third party service provider) collect
                                                information from you, including, but not limited to, your browser type,
                                                operating system, Internet Protocol (“IP”) address, mobile device ID,
                                                blockchain address, wallet type, and date/time stamps.
                                            </li>
                                            <li>
                                                Log Files. As is true of most websites and applications, we gather
                                                certain information automatically and store it in log files. This
                                                information includes IP addresses, browser type, Internet service
                                                provider (“ISP”), referring/exit pages, operating system, date/time
                                                stamps, and clickstream data. We use this information to analyze trends,
                                                administer the Service, track users’ movements around the Service, and
                                                better tailor our Services to our users’ needs. For example, some of the
                                                information may be collected so that when you visit the Service, it will
                                                recognize you and the information can be used to personalize your
                                                experience.
                                            </li>
                                            <li>
                                                Cookies. Like many online services, we use cookies to collect
                                                information. We may use both session Cookies (which expire once you
                                                close your web browser) and persistent Cookies (which stay on your
                                                computer until you delete them) to analyze how users interact with our
                                                Service, make improvements to our product quality, and provide users
                                                with a more personalized experience.
                                            </li>
                                            <li>
                                                Pixel Tag. In addition, we use “Pixel Tags” (also referred to as clear
                                                Gifs, Web beacons, or Web bugs). Pixel Tags allow us to analyze how
                                                users find our Service, make the Service more useful to you, and tailor
                                                your experience with us to meet your particular interests and needs.
                                            </li>
                                            <li>
                                                How We Respond to Do Not Track Signals. Our systems do not currently
                                                recognize “do not track” signals or other mechanisms that might enable
                                                Users to opt out of tracking on our site.
                                            </li>
                                            <li>
                                                Analytics Services. In addition to the tracking technologies we place
                                                like Cookies and Pixel Tags, other companies may set their own cookies
                                                or similar tools when you visit our Service. This includes third-party
                                                analytics services (“Analytics Services”) that we engage to help analyze
                                                how users use the Service. The information generated by the Cookies or
                                                other technologies about your use of our Service (the “Analytics
                                                Information”) is transmitted to the Analytics Services. The Analytics
                                                Services use Analytics Information to compile reports on user activity,
                                                which we may receive on an individual or aggregate basis. We use the
                                                information we get from Analytics Services to improve our Service. The
                                                Analytics Services may also transfer information to third parties where
                                                required to do so by law, or where such third parties process Analytics
                                                Information on their behalf. Each Analytics Services’ ability to use and
                                                share Analytics Information is restricted by such Analytics Services’
                                                terms of use and privacy policy. By using our Service, you consent to
                                                the processing of data about you by Analytics Services in the manner and
                                                for the purposes set out above.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>Information Collected from Third-Party Companies.</strong> We may
                                        receive Personal and/or Anonymous Data about you from companies that offer their
                                        products and/or services for use in conjunction with our Service or whose
                                        products and/or services may be linked from our Service. For example,
                                        third-party wallet providers provide us with your blockchain address and certain
                                        other information you choose to share with those wallets providers. We may add
                                        this to the data we have already collected from or about you through our
                                        Service.
                                    </li>
                                    <li>
                                        <strong>Public Information Observed from Blockchains.</strong> We collect data
                                        from activity that is publicly visible and/or accessible on blockchains. This
                                        may include blockchain addresses and information regarding purchases, sales, or
                                        transfers of NFTs, which may then be associated with other data you have
                                        provided to us.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <strong>Use of Your Personal Data</strong>
                                <ol className='mb-4' type='a'>
                                    <li>
                                        We process your Personal Data to run our business, provide the Service,
                                        personalize your experience on the Service, and improve the Service.
                                        Specifically, we use your Personal Data to:
                                        <ol className='mb-4' type='i'>
                                            <li>facilitate the creation of and secure your account;</li>
                                            <li>identify you as a user in our system;</li>
                                            <li>
                                                provide you with our Service, including, but not limited to, helping you
                                                view, explore, and create NFTs using our tools and, at your own
                                                discretion, connect directly with others to purchase, sell, or transfer
                                                NFTs on public blockchains;
                                            </li>
                                            <li>
                                                improve the administration of our Service and quality of experience when
                                                you interact with our Service, including, but not limited to, by
                                                analyzing how you and other users find and interact with the Service;
                                            </li>
                                            <li>
                                                provide customer support and respond to your requests and inquiries;
                                            </li>
                                            <li>
                                                investigate and address conduct that may violate our Terms of Service;
                                            </li>
                                            <li>
                                                detect, prevent, and address fraud, violations of our terms or policies,
                                                and/or other harmful or unlawful activity;
                                            </li>
                                            <li>
                                                display your username next to the NFTs currently or previously
                                                accessible in your third-party wallet, and next to NFTs on which you
                                                have interacted;
                                            </li>
                                            <li>
                                                send you a welcome email to verify ownership of the email address
                                                provided when your account was created;
                                            </li>
                                            <li>
                                                send you administrative notifications, such as security, support, and
                                                maintenance advisories;
                                            </li>
                                            <li>
                                                send you notifications related to actions on the Service, including
                                                notifications of offers on your NFTs;
                                            </li>
                                            <li>
                                                send you newsletters, promotional materials, and other notices related
                                                to our Services or third parties' goods and services;
                                            </li>
                                            <li>
                                                respond to your inquiries related to employment opportunities or other
                                                requests;
                                            </li>
                                            <li>
                                                comply with applicable laws, cooperate with investigations by law
                                                enforcement or other authorities of suspected violations of law, and/or
                                                to pursue or defend against legal threats and/or claims; and
                                            </li>
                                            <li>
                                                act in any other way we may describe when you provide the Personal Data.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        We may create Anonymous Data records from Personal Data. We use this Anonymous
                                        Data to analyze request and usage patterns so that we may improve our Services
                                        and enhance Service navigation. We reserve the right to use Anonymous Data for
                                        any purpose and to disclose Anonymous Data to third parties without restriction.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <strong>Disclosure of Your Personal Data.</strong> We disclose your Personal Data as
                                described below and as described elsewhere in this Privacy Policy
                                <ol className='mb-4' type='a'>
                                    <li>
                                        <strong>Third Party Service Providers.</strong> We may share your Personal Data
                                        with third party service providers to: provide technical infrastructure
                                        services; conduct quality assurance testing; analyze how our Service is used;
                                        prevent, detect, and respond to unauthorized activities; provide technical and
                                        customer support; and/or to provide other support to us and to the Service.
                                    </li>
                                    <li>
                                        <strong>Affiliates.</strong> We may share some or all of your Personal Data with
                                        any subsidiaries, joint ventures, or other companies under our common control
                                        (“Affiliates”), in which case we will require our Affiliates to honor this
                                        Privacy Policy.
                                    </li>
                                    <li>
                                        <strong>Corporate Restructuring. </strong>We may share some or all of your
                                        Personal Data in connection with or during negotiation of any merger, financing,
                                        acquisition, or dissolution transaction or proceeding involving sale, transfer,
                                        divestiture, or disclosure of all or a portion of our business or assets. In the
                                        event of an insolvency, bankruptcy, or receivership, Personal Data may also be
                                        transferred as a business asset. If another company acquires our company,
                                        business, or assets, that company will possess the Personal Data collected by us
                                        and will assume the rights and obligations regarding your Personal Data as
                                        described in this Privacy Policy.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PrivacyPolicyPage;
