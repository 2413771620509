import React from 'react';
import { Link } from 'react-router-dom';
import { formteFullDate, truncateStart } from '../../helpers/utils';
import { settings } from '../../helpers/settings';
import DataTable from 'react-data-table-component';
import { BsPatchCheckFill } from 'react-icons/bs';

// HOOKS
import useMarketplace from '../../hooks/useMarketplace';
import useUser from '../../hooks/useUser';

// COMPONENTS
import ImageCpt from '../../components/general/ImageCpt';

function ExploreListTable({ data }) {
    const userCtx = useUser();
    const marketplaceCtx = useMarketplace();
    function getUserAvatar(address) {
        return userCtx?.usersList?.filter((user) => user?.account === address)[0]?.avatar;
    }
    function getUserName(address) {
        return userCtx?.usersList?.filter((user) => user?.account === address)[0]?.fullName;
    }

    /*** =============================================== */
    //      TABLE COLUMNS
    /*** =============================================== */
    const columns = [
        {
            name: 'NFT Title',
            selector: (row) => row.title,
            cell: (row) => (
                <div row={row}>
                    <Link className='text-reset' to={`/assets/${row.id}`}>
                        <div className='d-flex align-items-center py-3 overflow-hidden'>
                            <div className='author-avatar rounded-xl overflow-hidden'>
                                {row.type === 'image' && <ImageCpt type='image' img={row.img} />}
                                {row.type === 'audio' && (
                                    <>
                                        <span
                                            className='author-avatar-inner rounded-xl'
                                            style={{ background: `linear-gradient(45deg, #4ca1af, #c4e0e5)` }}
                                        ></span>
                                        <i className='las la-music text-white position-absolute top-50 start-50 translate-middle z-index-20'></i>
                                    </>
                                )}
                                {row.type === 'video' && (
                                    <div className='player-wrapper z-index-20'>
                                        <ImageCpt type='video' img={row.img} />
                                    </div>
                                )}
                            </div>
                            <div className='ms-3'>
                                <p className='fw-bold text-base mb-0'>{truncateStart(row.title, 15)}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: 'Price',
            selector: (row) => row.price,
            cell: (row) => (
                <p className='text-base mb-0'>
                    {row.price > 0 ? (
                        <span>
                            {(row.price / 10 ** 18).toFixed(2)} {settings.currency}
                        </span>
                    ) : (
                        'Not Set'
                    )}
                </p>
            ),
        },
        {
            name: 'Category',
            selector: (row) => row.category,
            cell: (row) => <p className='text-base mb-0'>{row?.category}</p>,
        },
        {
            name: 'Date Created',
            selector: (row) => row.dateCreated,
            cell: (row) => (
                <div row={row}>
                    <div className='d-flex align-items-center'>
                        <p className='mb-0 fw-bold'>{formteFullDate(row.dateCreated)}</p>
                    </div>
                </div>
            ),
        },
        {
            name: 'Unlockable',
            selector: (row) => row.unlockable,
            cell: (row) => (
                <div row={row}>
                    <div className='d-flex align-items-center'>
                        <p className='mb-0'>{row.unlockable !== '' ? 'Yes' : 'No'}</p>
                    </div>
                </div>
            ),
        },
        {
            name: 'Royalties',
            selector: (row) => row.royalties,
            cell: (row) => <p className='text-base mb-0'>{row.royalties}%</p>,
        },
        {
            name: 'Owner',
            selector: (row) => row.owner,
            cell: (row) => (
                <Link className='text-reset' to={`/artists/${row?.owner}`}>
                    <div className='d-flex align-items-center py-3 px-3'>
                        <div className='author-avatar'>
                            <span
                                className='author-avatar-inner'
                                style={{ background: `url(${getUserAvatar(row?.owner)})` }}
                            ></span>
                        </div>
                        <div className='ms-2 h6 text-sm mb-0'>
                            <div className='d-flex align-items-center'>
                                {getUserName(row?.owner)}
                                {userCtx?.verifiedUsers?.includes(row.owner) && (
                                    <span className='blue-tick static'>
                                        <BsPatchCheckFill
                                            size='0.8rem'
                                            className='ms-1'
                                            style={{ transform: 'translateY(-2px)' }}
                                        />
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </Link>
            ),
        },
    ];

    return (
        <>
            {data?.length > 0 && (
                <>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination={data.length >= 10 && true}
                        responsive
                        theme={marketplaceCtx.themeMode === 'dark' && 'solarized'}
                    />
                </>
            )}
        </>
    );
}

export default ExploreListTable;
